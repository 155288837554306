import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    principalAnnualGrossIncome,
    principalIsPG,
    applicantSameAsPrincipal,
  },
} = loanFormModel;

export class PrincipalAnnualGrossIncomeValidation implements ValidationSchema {
  public name = principalAnnualGrossIncome.name;
  public label = principalAnnualGrossIncome.label;
  public dependsOn: string[] = [
    principalIsPG.name,

    applicantSameAsPrincipal.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalIsPG: string, applicantSameAsPrincipal: string, schema) {
    if (principalIsPG === 'Yes' && applicantSameAsPrincipal === 'Yes') {
      return schema.required(principalAnnualGrossIncome.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalAnnualGrossIncomeValidation());
