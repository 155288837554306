import SelectField from './SelectField';
import CheckboxField from './CheckboxField';
import { withInfoBox } from '../loan_application/components/withInfoBox';
import DatePickerField from './DatePickerField';
import FreeformField from './FreeformField';
import InputField from './InputField';
import NumberField from './NumberField';
import RadioGroupFields from './RadioGroupFields';

// TODO: The only component used here is InputFieldWithInfoBox
// These are all duplicated in hook_form_fields
// Replace with hook_form_fields and remove these components
export const CheckboxFieldWithInfoBox = withInfoBox(CheckboxField);
export const SelectFieldWithInfoBox = withInfoBox(SelectField);
export const DatePickerFieldWithInfoBox = withInfoBox(DatePickerField);
export const FreeformFieldWithInfoBox = withInfoBox(FreeformField);
export const InputFieldWithInfoBox = withInfoBox(InputField);
export const NumberFieldWithInfoBox = withInfoBox(NumberField);
export const RadioGroupFieldWithInfoBox = withInfoBox(RadioGroupFields);
