import loanFormModel from '../../components/loan_application/form_models/loanFormModel';

import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { systemPropertyAddressIsSameAsBusiness },
} = loanFormModel;

export class PropertyAddressIsSameAsBusinessValidation
  implements ValidationSchema
{
  public name: string = systemPropertyAddressIsSameAsBusiness.name;
  public label: string =
    systemPropertyAddressIsSameAsBusiness.getLabel('Borrower');
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyAddressIsSameAsBusinessValidation());
