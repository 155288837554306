import { YesNo } from '../../../../../types/api';
import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  applicantFiledTaxReturnsInThePast,
  taxReturnsExtension,
  applicantFiledLastYearTaxReturns,
} = taxDocuments;

const { systemPowerUsage } = loanFormModel.formField;

export class TaxReturnsExtensionValidation implements ValidationSchema {
  public name = taxReturnsExtension.name;
  public label = taxReturnsExtension.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    applicantFiledLastYearTaxReturns.name,
    systemPowerUsage.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    applicantFiledTaxReturnsInThePast: YesNo,
    applicantFiledLastYearTaxReturns,
    systemPowerUsage,
    schema
  ) {
    const required =
      taxReturnsExtension.displayCondition(
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns
      ) && taxReturnsExtension.getRequired(systemPowerUsage);
    return FileNameValidationSchema(taxReturnsExtension.name, required);
  }
}
export default new Validation(new TaxReturnsExtensionValidation());
