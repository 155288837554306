import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { financialStatementQ1 } = taxDocuments;

const { systemPowerUsage, isPG } = loanFormModel.formField;

export class FinancialStatementQ1Validation implements ValidationSchema {
  public name = financialStatementQ1.name;
  public label = financialStatementQ1.getLabel(defaultApplicantName);
  public dependsOn: string[] = [isPG.name, systemPowerUsage.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(isPersonalGuarantor, systemPowerUsage, schema) {
    const required =
      financialStatementQ1.displayCondition(isPersonalGuarantor) &&
      financialStatementQ1.getRequired(systemPowerUsage);
    return FileNameValidationSchema(financialStatementQ1.name, required);
  }
}
export default new Validation(new FinancialStatementQ1Validation());
