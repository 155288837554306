import { Validation } from './Validation';
import { ValidationSchema } from './interfaces';
import { defaultRequiredDocErrMessage } from '../utils/validation';
import * as Yup from 'yup';

export class FileNameValidationSchema implements ValidationSchema {
  public name = '';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  constructor(label: string, required?: boolean) {
    this.label = label;
    if (required)
      this.required = {
        message: defaultRequiredDocErrMessage,
        value: true,
      };
  }
  public buildShape(schema) {
    const { required } = this;

    return Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            file_name: schema,
          })
        )
        .test('check-first-file', 'File name is required', (files) => {
          if (!files || files.length === 0) {
            return !required.value; // Pass validation if files array is empty or doesn't exist
          }
          return required.value ? !!files[0]?.file_name : true; // Validate file_name only if required
        }),
    });
  }
}

export default (label: string, required?: boolean) =>
  new Validation(new FileNameValidationSchema(label, required)).buildSchema();
