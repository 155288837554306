import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { mailingAddressSameAsBusinessPicklist },
} = loanFormModel;

export class MailingAddressSameAsBusinessPicklistValidation
  implements ValidationSchema
{
  public name: string = mailingAddressSameAsBusinessPicklist.name;
  public label: string = mailingAddressSameAsBusinessPicklist.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'An answer is required, please select an option',
    value: true,
  };
  public nullable = true;
}
export default new Validation(
  new MailingAddressSameAsBusinessPicklistValidation()
);
