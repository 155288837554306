import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalPercentOwned },
} = loanFormModel;

export class PrincipalPercentOwnedValidation implements ValidationSchema {
  public name = principalPercentOwned.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'number';
  public required = {
    message: principalPercentOwned.requiredErrorMsg,
    value: principalPercentOwned.required,
  };
  public nullable = true;
  public typeError = {
    message: 'Must be a number',
    value: true,
  };
  public min = {
    message: 'Percentage must be a positive number',
    value: 0,
  };
  public max = {
    message: 'Please enter a valid percentage',
    value: 100,
  };
  public when(schema) {
    return schema.test(
      'is-valid-percentage',
      "Ownership percentage total for all principals can't exceed 100%",
      (value, testContext: any) => {
        // Uses undocumented testContext.from to access the parent array.
        const [_parent, application] = testContext.from;
        const total = application?.value?.principals
          .map((p) => Number(p[principalPercentOwned.name]))
          .reduce((prev, curr) => prev + curr, 0);
        return total <= 100;
      }
    );
  }
}

export default new Validation(new PrincipalPercentOwnedValidation());
