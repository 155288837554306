import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { RELATED_PARTY_TYPES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType },
} = loanFormModel;

export class PrincipalIdDocumentFileNameValidation implements ValidationSchema {
  public name = 'file_name';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(schema) {
    return schema.test(
      'check-required-if-undefined',
      'This document is required',
      function (value) {
        // this.from[3] traverses back up the object tree to the principal so that we can read principal_type
        if (
          (this as any).from[3].value[principalType.name] ===
          RELATED_PARTY_TYPES.INDIVIDUAL
        ) {
          return value != undefined && value != null && value !== '';
        }

        return true;
      }
    );
  }
}

export default new Validation(new PrincipalIdDocumentFileNameValidation());
