import { ValidationSchema } from './interfaces';
import { Validation } from './Validation';

export class GenericValidation implements ValidationSchema {
  name: string;
  label: string;
  type: string;
  required: { value: boolean; message: string };
  min?: { value: number; message: string } | undefined;
  max?: { value: number; message: string } | undefined;
  matches?: { value: RegExp; message: string } | undefined;
  nullable?: boolean | undefined;
  positive?: boolean | undefined;
  buildShape?: any;
  dependsOn: string[];

  constructor(data: any) {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.required = data.required;
    this.nullable = data.nullable;
    this.dependsOn = data.dependsOn;
  }

  static create(data: any) {
    return new Validation(new GenericValidation(data)).buildSchema();
  }
}
