import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { proofOfOfftakerCreditworthiness } =
  loanFormModel.formField.solarSystemDocuments;

export class ProofOfOfftakerCreditworthinessValidation
  implements ValidationSchema
{
  public name = proofOfOfftakerCreditworthiness.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public min = {
    message: '',
    value: 0,
  };

  public when(schema) {
    return schema.of(
      FileNameValidationSchema(proofOfOfftakerCreditworthiness.name, false)
    );
  }
}
export default new Validation(new ProofOfOfftakerCreditworthinessValidation());
