import { useDropzone, DropzoneOptions, DropEvent } from 'react-dropzone';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import bytesToSize from '../utils/bytesToSize';
import { DocumentIcon, InfoIcon, UploadIcon } from './loan_application/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import format from 'date-fns/format';
import type { DistributiveOmit } from '@mui/types';
import type { SunstoneDocument } from '../../../../types/api';
import type { FC, ReactNode } from 'react';
import { CustomTooltip } from './CustomTooltip';
import { FormHelperText } from '@mui/material';
import { shortenFilename } from './loan_application/utils/helpers';
type BoxDropzoneIntersection = Extract<keyof BoxProps, keyof DropzoneOptions>;

export interface SunstoneDocumentFileDropzoneProps
  extends DropzoneOptions,
    DistributiveOmit<BoxProps, BoxDropzoneIntersection> {
  label?: ReactNode;
  required?: boolean;
  tooltip?: string;
  uploadText?: ReactNode;
  loading?: boolean;
  error?: ReactNode;
  file?: SunstoneDocument;
  endAdornment?: ReactNode;
  canReplace?: boolean;
  canDownload?: boolean;
  hideMaxSize?: boolean;
  remove?: any;
  removing?: boolean;
  additionalInfo?: string;
  onError?: (error: Error | null) => void;
}

const SunstoneDocumentFileDropzone: FC<SunstoneDocumentFileDropzoneProps> = (
  props
) => {
  const {
    accept,
    label,
    required,
    disabled,
    file,
    getFilesFromEvent,
    maxSize,
    hideMaxSize,
    minSize,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onError,
    canReplace,
    canDownload,
    remove,
    removing,
    preventDropOnDocument,
    error,
    loading,
    endAdornment,
    tooltip,
    uploadText,
    additionalInfo,
    ...other
  } = props;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    maxFiles: 1,
    maxSize,
    minSize,
    onDrop: (acceptedFiles, fileRejections) => {
      // This implementation is to handle the file replacement and the error when the file being uploaded is too large
      if (fileRejections.length > 0) {
        const error = fileRejections[0].errors[0];
        let errorMessage = error.message;
        if (error.code === 'file-too-large') {
          errorMessage = `File is too large. Max size is ${bytesToSize(
            maxSize
          )}.`;
          onError && onError(new Error(errorMessage));
        }
      } else if (acceptedFiles.length > 0 && onDrop) {
        onError && onError(null);
        onDrop(acceptedFiles, fileRejections, event as DropEvent);
      }
    },
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onError,
    disabled,
    noClick: true,
  });

  const hasEndAdornment = endAdornment !== undefined;

  const adornmentElement = hasEndAdornment ? (
    <Box
      sx={{
        width: '140px',
        backgroundColor: 'background.paper',
        flexGrow: 0,
        borderTopRightRadius: (theme) => theme.spacing(0.75),
        borderBottomRightRadius: (theme) => theme.spacing(0.75),
        borderColor: 'border.input',
        borderStyle: 'solid',
        borderWidth: '1px',
        p: 1.25,
      }}
    >
      {endAdornment}
    </Box>
  ) : null;

  return (
    <Box {...other}>
      <Box component="label">
        {label && (
          <Box display="flex" justifyContent={'start'}>
            <Typography variant="body1" mb={1.5} component="span">
              {label}
              {tooltip && (
                <CustomTooltip title={tooltip}>
                  <InfoIcon
                    role={'tooltip'}
                    sx={{
                      fontSize: '0.75rem',
                      marginTop: '.175rem',
                      marginLeft: '.5rem',
                      verticalAlign: 'center',
                    }}
                  />
                </CustomTooltip>
              )}
              {required && (
                <Typography variant="body2" color="error" component="span">
                  {' '}
                  *
                </Typography>
              )}
              {additionalInfo && (
                <FormHelperText
                  sx={{
                    margin: 0,
                  }}
                >
                  <Box
                    textAlign={'left'}
                    component={'span'}
                    display={'block'}
                    color={'black'}
                    padding={0.5}
                    bgcolor={'#F4F4F4'}
                    borderRadius={'5px'}
                    mt={2}
                  >
                    {additionalInfo}
                  </Box>
                </FormHelperText>
              )}
            </Typography>
          </Box>
        )}
        {file ? (
          <Box display="flex">
            <Box
              sx={{
                backgroundColor: 'background.input',
                borderColor: 'border.input',
                borderRadius: (theme) => theme.spacing(0.75),
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRight: hasEndAdornment ? 'none' : undefined,
                borderTopRightRadius: (theme) =>
                  hasEndAdornment ? 0 : theme.spacing(0.75),
                borderBottomRightRadius: (theme) =>
                  hasEndAdornment ? 0 : theme.spacing(0.75),
                py: 1.375,
                px: 2,
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} disabled={disabled} />
              <DocumentIcon />
              <div title={file.original_file_name ?? ''}>
                <Typography
                  variant="body2"
                  ml={1.25}
                  component="span"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  maxWidth="240px"
                >
                  {shortenFilename(
                    file?.original_file_name || file?.name || 'unnamed_file',
                    30
                  )}
                </Typography>
              </div>
              {file.last_modified_date && (
                <Typography variant="body2" color="text.secondary" ml={1.25}>
                  Uploaded{' '}
                  {format(
                    new Date(file.last_modified_date),
                    "MM/dd/yyyy 'at' h:mm aaa"
                  )}
                </Typography>
              )}
              <Box ml="auto" display="flex">
                {canDownload && (
                  <Button
                    href={file.url ?? ''}
                    download
                    variant="text"
                    color="primary"
                    sx={{ py: 0.25, px: 0.5 }}
                  >
                    Download
                  </Button>
                )}
                {canReplace && (
                  <LoadingButton
                    variant="text"
                    color="primary"
                    onClick={open}
                    sx={{ py: 0.25, px: 0.5 }}
                    loading={loading}
                    disabled={disabled}
                  >
                    Replace
                  </LoadingButton>
                )}
                {remove &&
                  (removing ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloseIcon
                      role={'button'}
                      aria-label={'Remove'}
                      aria-hidden={false}
                      onClick={(e) => {
                        remove();
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      sx={{ color: '#868795' }}
                    />
                  ))}
              </Box>
            </Box>
            {adornmentElement}
          </Box>
        ) : (
          <Box display="flex">
            <Box
              sx={{
                borderRadius: (theme) => theme.spacing(0.75),
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRight: hasEndAdornment ? 'none' : undefined,
                borderTopRightRadius: (theme) =>
                  hasEndAdornment ? 0 : theme.spacing(0.75),
                borderBottomRightRadius: (theme) =>
                  hasEndAdornment ? 0 : theme.spacing(0.75),
                py: 1.375,
                px: 2,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'background.input',
                borderColor: 'border.input',
                justifyContent: 'center',
                flexGrow: 1,
                ...(isDragActive && {
                  backgroundColor: 'action.active',
                  opacity: 0.5,
                }),
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                },
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} disabled={disabled} />
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {uploadText ? (
                    <Typography
                      variant="body2"
                      component="span"
                      color="primary"
                    >
                      {uploadText}
                    </Typography>
                  ) : (
                    <>
                      <UploadIcon sx={{ fontSize: 20, mr: 1 }} />
                      <Typography
                        variant="body2"
                        component="span"
                        color="primary"
                      >
                        Upload File
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
            {adornmentElement}
          </Box>
        )}
      </Box>
      <Typography
        variant="body2"
        display={'flex'}
        mt={0.5}
        color="text.secondary"
        justifyContent={'space-between'}
      >
        <Box component="span" sx={{ color: 'error.main' }}>
          {error ?? null}
        </Box>
        {maxSize && !hideMaxSize && (
          <span>Max upload file size: {bytesToSize(maxSize)}</span>
        )}
      </Typography>
    </Box>
  );
};

export default SunstoneDocumentFileDropzone;
