import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    systemPropertyOwnershipStatus,
    systemPropertyLeaseExpirationYear,
    systemPropertyCurrentlyPayingMortgage,
    loanLender,
    outstandingLoanType,
    loanBalance,
    loanYearOfMaturity,
    loanMonthlyPayment,
  },
} = loanFormModel;

export class OutstandingLoanValidation implements ValidationSchema {
  public name = 'outstanding_loans';
  public label = '';
  public dependsOn: string[] = [
    systemPropertyOwnershipStatus.name,
    systemPropertyCurrentlyPayingMortgage.name,
  ];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(
    ownershipType: string,
    payingMortgage: YesNo,
    schema: Yup.ArraySchema<Yup.ObjectSchema<any>>
  ) {
    if (ownershipType === 'OWNED' && payingMortgage === 'Yes') {
      return schema
        .of(
          Yup.object().shape({
            [loanLender.name]: Yup.string().when('on_system_property', {
              is: 'Yes',
              then: (schema) =>
                schema.required(`${loanLender.requiredErrorMsg}`).nullable(),
              otherwise: (schema) => schema.nullable(),
            }),
            [outstandingLoanType.name]: Yup.string().when(
              'on_system_property',
              {
                is: 'Yes',
                then: (schema) =>
                  schema
                    .required(`${outstandingLoanType.requiredErrorMsg}`)
                    .nullable(),
                otherwise: (schema) => schema.nullable(),
              }
            ),
            [loanBalance.name]: Yup.number()
              .when('on_system_property', {
                is: 'Yes',
                then: (schema) =>
                  schema.required(`${loanBalance.requiredErrorMsg}`).nullable(),
                otherwise: (schema) => schema.nullable(),
              })
              .typeError('Please enter a number.')
              .min(10000, 'Remaining principal must be greater than $10,000'),
            [loanYearOfMaturity.name]: Yup.number()
              .when('on_system_property', {
                is: 'Yes',
                then: (schema) =>
                  schema.required('This field is required').nullable(),
                otherwise: (schema) => schema.nullable(),
              })
              .typeError(genericRequiredFieldError)
              .min(
                new Date().getFullYear() + 1,
                'Loan should have a remaining term of at least 1 year'
              ),
            [loanMonthlyPayment.name]: Yup.number()
              .when('on_system_property', {
                is: 'Yes',
                then: (schema) =>
                  schema
                    .required(`${loanMonthlyPayment.requiredErrorMsg}`)
                    .nullable(),
                otherwise: (schema) => schema.nullable(),
              })
              .typeError('Please enter a number.')
              .min(0.01, 'Please enter a number greater than 0')
              .max(
                Yup.ref(loanBalance.name),
                'Please enter an amount less than the total loan balance'
              ),
          })
        )
        .min(1, 'Please add at least one loan.')
        .test(
          'at-least-one-on-system-property',
          'At least one loan must have "on_system_property" set to "Yes"',
          function (loans: any[]) {
            return loans.some((loan) => loan.on_system_property === 'Yes');
          }
        );
    }
    return schema;
  }
}
export default new Validation(new OutstandingLoanValidation());
