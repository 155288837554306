import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { RELATED_PARTY_TYPES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType, principalSsn, principalIsUSCitizen },
} = loanFormModel;

export class PrincipalSsnValidation implements ValidationSchema {
  public name = principalSsn.name;
  public label = principalSsn.label;
  public dependsOn: string[] = [principalType.name, principalIsUSCitizen.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, principalIsUSCitizen: string, schema) {
    if (
      principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
      principalIsUSCitizen === 'Yes'
    ) {
      return schema
        .required(principalSsn.requiredErrorMsg)
        .min(9, 'Social Security Number has 9 digits')
        .max(9, 'Social Security Number has 9 digits');
    }
    return schema.transform(() => null);
  }
}

export default new Validation(new PrincipalSsnValidation());
