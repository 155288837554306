import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  systemPropertyOwnershipStatus,
  isPG,
  systemPropertyCurrentlyPayingMortgage,
  companyDocuments: { pgMortgageStatement },
} = loanFormModel.formField;

export class PgMortgageStatementValidation implements ValidationSchema {
  public name = pgMortgageStatement.name;
  public label = '';
  public dependsOn: string[] = [
    systemPropertyOwnershipStatus.name,
    systemPropertyCurrentlyPayingMortgage.name,
    isPG.name,
  ];
  public type = 'mixed';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist'],
    systemPropertyCurrentlyPayingMortgage: Application['borrower_is_currently_paying_mortgage'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      isPersonalGuarantor === 'Yes' &&
      systemPropertyCurrentlyPayingMortgage === 'Yes' &&
      systemPropertyOwnershipStatus !== 'LEASED';

    if (required)
      return FileNameValidationSchema(pgMortgageStatement.name, true).required(
        'This document is required'
      );
    return FileNameValidationSchema(pgMortgageStatement.name, false).nullable();
  }
}
export default new Validation(new PgMortgageStatementValidation());
