import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';
import { RelatedParty } from '../../../../../types/api';
import { RELATED_PARTY_TYPES } from '../../constants';

const {
  formField: { principalCertificateOfTrust, principalType },
} = loanFormModel;

export class PrincipalCertificateOfTrustValidation implements ValidationSchema {
  public name = 'documents';
  public label = '';
  public dependsOn: string[] = [principalType.name];
  public type = 'object';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(
    principalType: RelatedParty['principal_type'],
    schema: Yup.ObjectSchema<any>
  ) {
    if (principalType === RELATED_PARTY_TYPES.ENTITY)
      return schema.shape({
        [principalCertificateOfTrust.name]: Yup.object().shape({
          files: Yup.array()
            .of(
              Yup.object().shape({
                file_name: Yup.string(),
              })
            )
            .test(
              'check-required-if-undefined',
              'This document is required',
              function (files) {
                return !!(
                  files &&
                  files[0]?.file_name != null &&
                  files[0]?.file_name !== ''
                );
              }
            ),
        }),
      });
    return schema;
  }
}
export default new Validation(new PrincipalCertificateOfTrustValidation());
