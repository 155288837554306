import { taxDocuments } from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const { applicantFiledTaxReturnsInThePast } = taxDocuments;

export class ApplicantFiledTaxReturnsInThePastValidation
  implements ValidationSchema
{
  public name = applicantFiledTaxReturnsInThePast.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: genericRequiredFieldError,
    value: true,
  };
  public nullable = true;
}
export default new Validation(
  new ApplicantFiledTaxReturnsInThePastValidation()
);
