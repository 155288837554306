import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { MINIMUM_LOAN_BALANCE } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { loanBalance },
} = loanFormModel;

export class LoanBalanceValidation implements ValidationSchema {
  public name = loanBalance.name;
  public label = loanBalance.label;
  public dependsOn: string[] = [];
  public type = 'number';
  public required = {
    message: loanBalance.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
  public min = {
    message: `Remaining principal must be greater than $${MINIMUM_LOAN_BALANCE}`,
    value: MINIMUM_LOAN_BALANCE,
  };

  public typeError = {
    message: 'Please enter a number.',
    value: true,
  };
}
export default new Validation(new LoanBalanceValidation());
