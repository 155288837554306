import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { loanLender },
} = loanFormModel;

export class LoanLenderValidation implements ValidationSchema {
  public name = loanLender.name;
  public label = loanLender.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: loanLender.requiredErrorMsg,
    value: loanLender.required,
  };
  public nullable = true;
  public max = {
    message: `${loanLender.label} must be less than ${loanLender.maxLength} characters`,
    value: loanLender.maxLength,
  };
}
export default new Validation(new LoanLenderValidation());
