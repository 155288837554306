import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { businessAddressState },
} = loanFormModel;

export class BusinessAddressStateValidation implements ValidationSchema {
  public name: string = businessAddressState.name;
  public label: string = businessAddressState.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: businessAddressState.required,
  };
  public nullable = true;
}
export default new Validation(new BusinessAddressStateValidation());
