import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { SYSTEM_POWER_USAGE_VALUES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { offTakerUtilityBill } = loanFormModel.formField.solarSystemDocuments;
const {
  systemPowerUsage,
  systemPowerSingleOrMultipleUsers,
  systemPowerCommunityOrPPALease,
} = loanFormModel.formField;

export class OffTakerUtilityBillValidation implements ValidationSchema {
  public name = offTakerUtilityBill.name;
  public label = '';
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
    systemPowerCommunityOrPPALease.name,
  ];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    systemPowerUsage: Application['solar_system_power_usage'],
    systemPowerSingleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
    systemPowerCommunityOrPPALease: Application['community_solar_or_ppa_lease'],
    schema
  ) {
    if (
      systemPowerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      (systemPowerSingleOrMultipleUsers === 'Single User' ||
        (systemPowerSingleOrMultipleUsers === 'Multiple Users' &&
          systemPowerCommunityOrPPALease == 'PPA/Lease Agreement'))
    ) {
      return schema
        .of(FileNameValidationSchema(offTakerUtilityBill.name, true))
        .min(1)
        .required('This document is required');
    }
    return schema
      .of(FileNameValidationSchema(offTakerUtilityBill.name, false))
      .notRequired();
  }
}
export default new Validation(new OffTakerUtilityBillValidation());
