import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';
import { YesNo } from '../../../../../types/api';

const { applicantFiledTaxReturnsInThePast, taxReturns3Year } = taxDocuments;

const { systemPowerUsage, borrowerBusinessEstablishedYear, isPG } =
  loanFormModel.formField;

export class TaxReturns3YearValidation implements ValidationSchema {
  public name = taxReturns3Year.name;
  public label = taxReturns3Year.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    borrowerBusinessEstablishedYear.name,
    isPG.name,
    systemPowerUsage.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    filedTaxReturns,
    businessEstablishedYear,
    isPersonalGuarantor: YesNo,
    systemPowerUsage,
    schema
  ) {
    const required =
      taxDocuments.taxReturns3Year.displayCondition(
        filedTaxReturns,
        businessEstablishedYear
      ) &&
      taxDocuments.taxReturns3Year.getRequired(systemPowerUsage) &&
      isPersonalGuarantor === 'No';
    return FileNameValidationSchema(taxReturns3Year.name, required);
  }
}
export default new Validation(new TaxReturns3YearValidation());
