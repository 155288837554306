import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { hoaBylaws } = loanFormModel.formField.hoaDocuments;

export class HoaBylawsValidation implements ValidationSchema {
  public name = hoaBylaws.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(schema) {
    return FileNameValidationSchema(hoaBylaws.name, false).notRequired();
  }
}
export default new Validation(new HoaBylawsValidation());
