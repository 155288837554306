import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  companyDocuments: { propertyCompanyMortgageStatement },
  systemPowerCompanyType,
  isPG,
  systemPropertyOwnershipStatus,
  systemPropertyCurrentlyPayingMortgage,
} = loanFormModel.formField;

export class PropertyCompanyMortgageStatementValidation
  implements ValidationSchema
{
  public name = propertyCompanyMortgageStatement.name;
  public label =
    propertyCompanyMortgageStatement.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    systemPropertyOwnershipStatus.name,
    systemPropertyCurrentlyPayingMortgage.name,
    isPG.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist'],
    systemPropertyCurrentlyPayingMortgage: Application['borrower_is_currently_paying_mortgage'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Property Company' &&
      systemPropertyOwnershipStatus === 'OWNED' &&
      systemPropertyCurrentlyPayingMortgage === 'Yes' &&
      isPersonalGuarantor !== 'Yes';
    schema = FileNameValidationSchema(
      propertyCompanyMortgageStatement.name,
      required
    );
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new PropertyCompanyMortgageStatementValidation());
