/* eslint-disable indent */
import {
  Application,
  InstallerDashboardSummary,
  InstallerProjectSummary,
  PaginatedResult,
  Rates,
  RequiredDocument,
  RequiredDocuments,
  WebPortalResource,
} from '../../../../../types/api';
import { RatesSchema } from '../../../../../types/schemas';
import {
  INSTALLER_BORROWERS_URL,
  INSTALLER_ACCOUNT,
  INSTALLER_APPLICATIONS_URL,
  INSTALLER_SUMMARY_URL,
  INSTALLER_PROJECT_SUMMARY_URL,
  PORTAL_RESOURCES_URL,
  PORTAL_RATES_URL,
  USER_APPLICATIONS_URL,
} from '../../constants';
import { BadRequest, CustomError, ServerError } from '../../utils/errors';
import { api } from './API';

export const getInstallerBorrowersAPI = async () => {
  try {
    const response = await api().get(INSTALLER_BORROWERS_URL);

    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export type InstallerApplicationsParams = {
  page?: number;
  size: number;
  search?: string | null;
  status?:
    | 'IN_PROGRESS'
    | 'APPROVED'
    | 'PENDING_CHANGE_ORDER'
    | 'CLOSED'
    | null;
};
export const getPaginatedInstallerApplications = async (
  params: InstallerApplicationsParams
) => {
  try {
    const response = await api().get<PaginatedResult<Application>>(
      `${INSTALLER_APPLICATIONS_URL}`,
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const getInstallerDashboardSummary = async () => {
  try {
    const response = await api().get<InstallerDashboardSummary>(
      INSTALLER_SUMMARY_URL
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const getInstallerProjectSummary = async () => {
  try {
    const response = await api().get<InstallerProjectSummary>(
      INSTALLER_PROJECT_SUMMARY_URL
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export type CloseApplicationVariables = {
  application_id: string;
  closed_reason: NonNullable<Application['closed_reason']>;
  close_requested_by: NonNullable<Application['close_requested_by']>;
  closed_sub_reason: NonNullable<Application['closed_sub_reason']>;
  closed_reason_notes: Application['closed_reason_notes'];
};
export const closeApplication = async (
  vars: CloseApplicationVariables
): Promise<Application> => {
  const { application_id, ...close_details } = vars;
  try {
    const res = await api().delete<Application>(
      `${INSTALLER_APPLICATIONS_URL}${application_id}`,
      {
        data: { ...close_details, internal_status: 'Closed' },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const getApplicationDocuments = async (application_id: string) => {
  try {
    const response = await api().get<{ data: RequiredDocuments }>(
      `${INSTALLER_APPLICATIONS_URL}${application_id}/documents`
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const uploadApplicationDocument = async (
  application_id: string,
  data: FormData
) => {
  try {
    const response = await api().post<{ data: RequiredDocument }>(
      `${INSTALLER_APPLICATIONS_URL}${application_id}/documents`,
      data,
      { timeout: 10 * 60 * 1000 }
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const uploadRailwayDocument = async (data: FormData) => {
  try {
    const response = await api().post<{ data: any }>(
      `${USER_APPLICATIONS_URL}railway-upload`,
      data,
      { timeout: 10 * 60 * 1000 }
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

/**
 * @param {object} data - data required to create a new borrower account and an application
 * @returns {Promise<string>} - returns the id of the new application
 */
export const createBorrowerAccount = async (data) => {
  try {
    const response = await api().post<{ id: string }>(
      INSTALLER_BORROWERS_URL,
      data
    );
    return response.data.id;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const getAccount = async (accountId, data) => {
  try {
    const response = await api().get(`${INSTALLER_ACCOUNT}${accountId}/`, data);
    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const updateAccount = async (accountId, data) => {
  try {
    const response = await api().put(`${INSTALLER_ACCOUNT}${accountId}/`, data);
    return response.data.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw getErrorByStatusCode(status, data?.detail, data?.errors);
    } else {
      throw getErrorByStatusCode(503);
    }
  }
};

export const listResources = async () => {
  const response = await api().get<WebPortalResource[]>(
    `${PORTAL_RESOURCES_URL}`
  );
  return response.data;
};

export const getRates = async (): Promise<Rates> => {
  const response = await api().get<unknown>(`${PORTAL_RATES_URL}`);
  const validatedData = RatesSchema.parse(response.data);
  return validatedData;
};

const getErrorByStatusCode = (
  status_code: number,
  displayError?: string,
  data?: Record<string, any>
) => {
  switch (status_code) {
    case 400:
      return new BadRequest({ code: status_code, message: displayError, data });

    case 409:
    case 410:
    case 422:
      return new CustomError({ code: status_code, message: displayError });
    case 423:
      // Special case SF errors
      return new ServerError({
        code: status_code,
        message: displayError,
        data,
      });
    case 503:
      return new ServerError({
        code: status_code,
        message: displayError,
        data,
      });

    default:
      return new ServerError({
        code: status_code,
        message: displayError,
        data,
      });
  }
};
