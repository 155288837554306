import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { meetingMinutes } = loanFormModel.formField.hoaDocuments;

export class MeetingMinutesValidation implements ValidationSchema {
  public name = meetingMinutes.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(schema) {
    return FileNameValidationSchema(meetingMinutes.name, false).notRequired();
  }
}
export default new Validation(new MeetingMinutesValidation());
