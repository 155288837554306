import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  companyDocuments: { articlesOfIncorporation },
  borrowerBusinessEstablishedYear,
  isPG,
} = loanFormModel.formField;

export class ArticlesOfIncorporationValidation implements ValidationSchema {
  public name = articlesOfIncorporation.name;
  public label = articlesOfIncorporation.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    borrowerBusinessEstablishedYear.name,
    isPG.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    borrowerBusinessEstablishedYear: Application['business_established_year'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const businessEstablishedYear = borrowerBusinessEstablishedYear;
    const currentYear = new Date().getFullYear();
    const required = !!(
      businessEstablishedYear &&
      currentYear - Number(businessEstablishedYear) < 3 &&
      isPersonalGuarantor !== 'Yes'
    );
    return FileNameValidationSchema(articlesOfIncorporation.name, required);
  }
}
export default new Validation(new ArticlesOfIncorporationValidation());
