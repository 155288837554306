import { YesNo } from '../../../../../types/api';
import { taxDocuments } from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { applicantFiledTaxReturnsInThePast, irsFormSS4 } = taxDocuments;

export class IrsFormSS4Validation implements ValidationSchema {
  public name = irsFormSS4.name;
  public label = irsFormSS4.getLabel(defaultApplicantName);
  public dependsOn: string[] = [applicantFiledTaxReturnsInThePast.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(applicantFiledTaxReturnsInThePast: YesNo, schema) {
    const notFiled = applicantFiledTaxReturnsInThePast === 'No';
    return FileNameValidationSchema(irsFormSS4.name, notFiled);
  }
}
export default new Validation(new IrsFormSS4Validation());
