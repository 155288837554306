import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalState },
} = loanFormModel;

export class PrincipalStateValidation implements ValidationSchema {
  public name = principalState.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: principalState.requiredErrorMsg,
    value: principalState.required,
  };
  public nullable = true;
}
export default new Validation(new PrincipalStateValidation());
