import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalRentOrOwn, principalIsPG, applicantSameAsPrincipal },
} = loanFormModel;

export class PrincipalRentOrOwnValidation implements ValidationSchema {
  public name = principalRentOrOwn.name;
  public label = principalRentOrOwn.label;
  public dependsOn: string[] = [
    principalIsPG.name,

    applicantSameAsPrincipal.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalIsPG: string, applicantSameAsPrincipal: string, schema) {
    if (principalIsPG === 'Yes' && applicantSameAsPrincipal === 'Yes') {
      return schema.required(principalRentOrOwn.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalRentOrOwnValidation());
