import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { RELATED_PARTY_TYPES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType, principalFirstName },
} = loanFormModel;

export class PrincipalFirstNameValidation implements ValidationSchema {
  public name = principalFirstName.name;
  public label = principalFirstName.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
      return schema.required(principalFirstName.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalFirstNameValidation());
