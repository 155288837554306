import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { additionalDocuments } = loanFormModel.formField.otherDocuments;

export class AdditionalDocumentsValidation implements ValidationSchema {
  public name = additionalDocuments.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public min = {
    message: '',
    value: 0,
  };
  public max = {
    message: '',
    value: 10,
  };

  public when(schema) {
    return schema.of(FileNameValidationSchema(additionalDocuments.name, false));
  }
}
export default new Validation(new AdditionalDocumentsValidation());
