import { taxDocuments } from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError, INTERVAL_DATES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const { applicantFiledTaxReturnsInThePast, applicantFiledLastYearTaxReturns } =
  taxDocuments;

export class ApplicantFiledLastYearTaxReturnsValidation
  implements ValidationSchema
{
  public name = applicantFiledLastYearTaxReturns.name;
  public label = '';
  public dependsOn: string[] = [applicantFiledTaxReturnsInThePast.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(applicantFiledTaxReturnsInThePast, schema) {
    if (applicantFiledTaxReturnsInThePast === 'Yes' && INTERVAL_DATES().MayOct)
      return schema.required(genericRequiredFieldError);
    return schema.notRequired();
  }
}
export default new Validation(new ApplicantFiledLastYearTaxReturnsValidation());
