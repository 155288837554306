import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import {
  genericRequiredFieldError,
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    systemPowerUsage,
    systemPowerSingleOrMultipleUsers,
    systemPowerCommunityOrPPALease,
    systemPowerLegalNamesOfOfftakers,
  },
} = loanFormModel;

export class LegalNamesOfOfftakersValidation implements ValidationSchema {
  public name: string = systemPowerLegalNamesOfOfftakers.name;
  public label: string = systemPowerLegalNamesOfOfftakers.getLabel(
    defaultBorrowerLegalName,
    false
  );
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
    systemPowerCommunityOrPPALease.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  when(
    usage: SYSTEM_POWER_USAGE_TYPE,
    singleOrMultipleUsers: string,
    communityOrPPALease: string,
    schema
  ) {
    if (
      usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      singleOrMultipleUsers === 'Multiple Users' &&
      communityOrPPALease === 'PPA/Lease Agreement'
    ) {
      return schema
        .required(genericRequiredFieldError)
        .nullable()
        .test(
          'are-legal-names-filled',
          'Please enter a legal name for each Offtaker.',
          (value) => {
            const offtakerNames = value?.split('\n') || [];
            if (offtakerNames.length === 0) {
              return false;
            }
            return offtakerNames.every((name) => name.length > 0);
          }
        );
    } else if (
      usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      singleOrMultipleUsers === 'Single User'
    ) {
      return schema.required(genericRequiredFieldError);
    }
    return schema;
  }
}
export default new Validation(new LegalNamesOfOfftakersValidation());
