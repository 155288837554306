import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { powerIsCommunitySolarCondition } from '../../components/loan_application/form_models/requiredDocumentsValidation';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { communitySolarFinancialProjections } =
  loanFormModel.formField.solarSystemDocuments;
const {
  systemPowerUsage,
  systemPowerSingleOrMultipleUsers,
  systemPowerCommunityOrPPALease,
} = loanFormModel.formField;

export class CommunitySolarFinancialProjectionsValidation
  implements ValidationSchema
{
  public name = communitySolarFinancialProjections.name;
  public label = '';
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
    systemPowerCommunityOrPPALease.name,
  ];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    powerUsage: Application['solar_system_power_usage'],
    singleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
    communityOrPPALease: Application['community_solar_or_ppa_lease'],
    schema
  ) {
    if (
      powerIsCommunitySolarCondition(
        powerUsage,
        singleOrMultipleUsers,
        communityOrPPALease
      )
    ) {
      return schema
        .of(
          FileNameValidationSchema(
            communitySolarFinancialProjections.name,
            true
          )
        )
        .min(1);
    }
    return schema
      .of(
        FileNameValidationSchema(communitySolarFinancialProjections.name, false)
      )
      .notRequired();
  }
}
export default new Validation(
  new CommunitySolarFinancialProjectionsValidation()
);
