import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  systemPowerCompanyType,
  propertyCompanyTenantRenewalYear,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
} = loanFormModel.formField;

export class PropertyCompanyTenantRenewalYearValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantRenewalYear.name;
  public label = propertyCompanyTenantRenewalYear.label;
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public positive = true;
  public nullable = true;
  public min = {
    value: new Date().getFullYear(),
    message: '',
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Property Company' &&
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        'Enter information for a single tenant';

    if (required) return schema.required('This document is required');
    else return schema.notRequired();
  }
}
export default new Validation(new PropertyCompanyTenantRenewalYearValidation());
