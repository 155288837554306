import { Application } from '../../../../../../types/api';
import { SYSTEM_POWER_USAGE_VALUES } from '../../../constants';

import loanFormModel from './loanFormModel';
import {
  AdditionalDocumentsValidation,
  MeetingMinutesValidation,
  CurrentOccupantLedgerValidation,
  HoaBylawsValidation,
  MemberAssessmentPolicyValidation,
  PriorYearBudgetsValidation,
  LeaseOfTheSystemPropertyValidation,
  OfftakerPPAorLeaseAgreementValidation,
  ProofOfOfftakerCreditworthinessValidation,
  OffTakerLeaseOrProofOfOwnershipValidation,
  OffTakerUtilityBillValidation,
  PpaLeaseFinancialProjectionsValidation,
  ApprovalForCommunitySolarDeliveryValidation,
  CommunitySolarFinancialProjectionsValidation,
  SubscriberManagementCompanyContractValidation,
  PrincipalsValidation,
  ArticlesOfIncorporationValidation,
  NonProfitBoardResolutionValidation,
  ReligiousOrganizationAttendanceValidation,
  NonProfitBylawsCopyValidation,
  PropertyCompanyMortgageStatementValidation,
  PropertyCompanyRentRollValidation,
  PropertyCompanyTenantMoveInYearValidation,
  PropertyCompanyTenantMonthlyRentPaymentValidation,
  PropertyCompanyTenantRenewalYearValidation,
  PropertyCompanyTenantRenewalLengthValidation,
  PropertyCompanyTenantLeaseTermValidation,
  PropertyCompanyUploadRentRollOrProvideTenantInfoValidation,
  PropertyCompanyTenantNameValidation,
  PropertyCompanyTenantPaymentsSatisfactoryValidation,
  PgMortgageStatementValidation,
} from '../../../validations/required_documents';

const {
  systemPowerCompanyType,
  borrowerIsReligiousOrganization,
  nonProfitRecievedBoardApproval,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  borrowerBusinessEstablishedYear,
  systemPropertyOwnershipStatus,
  systemPropertyCurrentlyPayingMortgage,
  // Tenant Info
  propertyCompanyTenantName,
  propertyCompanyTenantMoveInYear,
  propertyCompanyTenantLeaseTerm,
  propertyCompanyTenantRenewalYear,
  propertyCompanyTenantRenewalLength,
  propertyCompanyTenantMonthlyRentPayment,
  propertyCompanyTenantPaymentsSatisfactory,

  isPG,
} = loanFormModel.formField;

const {
  articlesOfIncorporation,
  nonProfitBoardResolution,
  nonProfitBylawsCopy,
  pgMortgageStatement,
  propertyCompanyRentRoll,
  propertyCompanyMortgageStatement,
  religiousOrganizationAttendance,
} = loanFormModel.formField.companyDocuments;

const enterTenantInfoCondition = (
  systemPowerCompanyType: Application['solar_system_power_company_type'],
  propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info']
) => {
  return (
    systemPowerCompanyType === 'Property Company' &&
    propertyCompanyUploadRentRollOrProvideTenantInfo ===
      'Enter information for a single tenant'
  );
};

const enterTenantLeaseTermCondition = (
  systemPowerCompanyType: Application['solar_system_power_company_type'],
  propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info']
) => {
  return (
    systemPowerCompanyType !== 'Property Company' &&
    propertyCompanyUploadRentRollOrProvideTenantInfo ===
      'Enter information for a single tenant'
  );
};

export const powerSoldToSingleUserCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Single User'
  );
};

export const powerIsCommunitySolarCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
  communityOrPPALease: Application['community_solar_or_ppa_lease']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Multiple Users' &&
    communityOrPPALease === 'Community Solar Arrangement'
  );
};

export const companyDocumentsValidation = {
  [ArticlesOfIncorporationValidation.name]:
    ArticlesOfIncorporationValidation.buildSchema(),
  [NonProfitBoardResolutionValidation.name]:
    NonProfitBoardResolutionValidation.buildSchema(),
  [ReligiousOrganizationAttendanceValidation.name]:
    ReligiousOrganizationAttendanceValidation.buildSchema(),
  [NonProfitBylawsCopyValidation.name]:
    NonProfitBylawsCopyValidation.buildSchema(),
  [PropertyCompanyMortgageStatementValidation.name]:
    PropertyCompanyMortgageStatementValidation.buildSchema(),
  [PropertyCompanyUploadRentRollOrProvideTenantInfoValidation.name]:
    PropertyCompanyUploadRentRollOrProvideTenantInfoValidation.buildSchema(),
  [PropertyCompanyRentRollValidation.name]:
    PropertyCompanyRentRollValidation.buildSchema(),
  [PropertyCompanyTenantNameValidation.name]:
    PropertyCompanyTenantNameValidation.buildSchema(),
  [PropertyCompanyTenantMoveInYearValidation.name]:
    PropertyCompanyTenantMoveInYearValidation.buildSchema(),
  [PropertyCompanyTenantMonthlyRentPaymentValidation.name]:
    PropertyCompanyTenantMonthlyRentPaymentValidation.buildSchema(),
  [PropertyCompanyTenantLeaseTermValidation.name]:
    PropertyCompanyTenantLeaseTermValidation.buildSchema(),
  [PropertyCompanyTenantRenewalYearValidation.name]:
    PropertyCompanyTenantRenewalYearValidation.buildSchema(),
  [PropertyCompanyTenantRenewalLengthValidation.name]:
    PropertyCompanyTenantRenewalLengthValidation.buildSchema(),
  [PropertyCompanyTenantPaymentsSatisfactoryValidation.name]:
    PropertyCompanyTenantPaymentsSatisfactoryValidation.buildSchema(),
  [PgMortgageStatementValidation.name]:
    PgMortgageStatementValidation.buildSchema(),
};

export const hoaDocumentsValidation = {
  [MemberAssessmentPolicyValidation.name]:
    MemberAssessmentPolicyValidation.buildSchema(),
  [PriorYearBudgetsValidation.name]: PriorYearBudgetsValidation.buildSchema(),
  [CurrentOccupantLedgerValidation.name]:
    CurrentOccupantLedgerValidation.buildSchema(),
  [MeetingMinutesValidation.name]: MeetingMinutesValidation.buildSchema(),
  [HoaBylawsValidation.name]: HoaBylawsValidation.buildSchema(),
};

export const solarSystemDocumentsValidation = {
  [LeaseOfTheSystemPropertyValidation.name]:
    LeaseOfTheSystemPropertyValidation.buildSchema(),
  // Single user
  [OfftakerPPAorLeaseAgreementValidation.name]:
    OfftakerPPAorLeaseAgreementValidation.buildSchema(),
  [ProofOfOfftakerCreditworthinessValidation.name]:
    ProofOfOfftakerCreditworthinessValidation.buildSchema(),
  [OffTakerLeaseOrProofOfOwnershipValidation.name]:
    OffTakerLeaseOrProofOfOwnershipValidation.buildSchema(),
  [OffTakerUtilityBillValidation.name]:
    OffTakerUtilityBillValidation.buildSchema(),
  [PpaLeaseFinancialProjectionsValidation.name]:
    PpaLeaseFinancialProjectionsValidation.buildSchema(),
  // Community solar
  [ApprovalForCommunitySolarDeliveryValidation.name]:
    ApprovalForCommunitySolarDeliveryValidation.buildSchema(),

  [CommunitySolarFinancialProjectionsValidation.name]:
    CommunitySolarFinancialProjectionsValidation.buildSchema(),
  [SubscriberManagementCompanyContractValidation.name]:
    SubscriberManagementCompanyContractValidation.buildSchema(),
  [PrincipalsValidation.name]: PrincipalsValidation.buildSchema(),
};

export const additionalDocumentationValidation = {
  [AdditionalDocumentsValidation.name]:
    AdditionalDocumentsValidation.buildSchema(),
};
