import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { loanPurpose, outstandingLoanType },
} = loanFormModel;

export class LoanPurposeValidation implements ValidationSchema {
  public name = loanPurpose.name;
  public label = loanPurpose.label;
  public dependsOn: string[] = [outstandingLoanType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(typeOfLoan: string, schema: Yup.StringSchema) {
    if (loanPurpose.getRequired(typeOfLoan)) {
      return schema.required(loanPurpose.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new LoanPurposeValidation());
