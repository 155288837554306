import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType },
} = loanFormModel;

export class PrincipalTypeValidation implements ValidationSchema {
  public name = principalType.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: principalType.requiredErrorMsg,
    value: principalType.required,
  };
  public nullable = true;
}
export default new Validation(new PrincipalTypeValidation());
