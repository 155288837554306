import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    systemPropertyAddressIsSameAsBusiness,
    systemPropertyAddressCity,
  },
} = loanFormModel;

export class PropertyAddressCityValidation implements ValidationSchema {
  public name: string = systemPropertyAddressCity.name;
  public label: string = systemPropertyAddressCity.label;
  public dependsOn: string[] = [systemPropertyAddressIsSameAsBusiness.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(isSameAsBusiness: YesNo, schema: Yup.StringSchema) {
    if (isSameAsBusiness === 'No') return schema.required();
    return schema;
  }
}
export default new Validation(new PropertyAddressCityValidation());
