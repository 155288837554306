import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { GenericValidation } from '../GenericValidation';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { systemPowerUsage, systemPowerCompanyType },
} = loanFormModel;

export class CompanyTypeValidation implements ValidationSchema {
  public name: string = systemPowerCompanyType.name;
  public label: string = systemPowerCompanyType.getLabel('Borrower');
  public dependsOn: string[] = [systemPowerUsage.name];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: systemPowerCompanyType.required,
  };
  public nullable = true;

  public when(systemPowerUsage: SYSTEM_POWER_USAGE_TYPE, schema) {
    if (
      systemPowerUsage ===
      SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL
    ) {
      return schema;
    }
    return GenericValidation.create({
      name: this.name,
      type: 'string',
      label: this.label,
      required: {
        value: false,
      },
      nullable: true,
    });
  }
}
export default new Validation(new CompanyTypeValidation());
