import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  genericRequiredFieldError,
  RELATED_PARTY_TYPES,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType, principalTrustYearEstablished },
} = loanFormModel;

export class PrincipalTrustYearEstablishedValidation
  implements ValidationSchema
{
  public name = principalTrustYearEstablished.name;
  public label = principalTrustYearEstablished.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.ENTITY) {
      return schema
        .required(genericRequiredFieldError)
        .min(1900, 'Please enter a valid year.')
        .max(new Date().getFullYear(), 'Please enter a valid year.');
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalTrustYearEstablishedValidation());
