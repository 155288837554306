import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { offTakerLeaseOrProofOfOwnership } =
  loanFormModel.formField.solarSystemDocuments;

export class OffTakerLeaseOrProofOfOwnershipValidation
  implements ValidationSchema
{
  public name = offTakerLeaseOrProofOfOwnership.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(schema) {
    return FileNameValidationSchema(
      offTakerLeaseOrProofOfOwnership.name,
      false
    );
  }
}
export default new Validation(new OffTakerLeaseOrProofOfOwnershipValidation());
