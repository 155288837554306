import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  companyDocuments: { propertyCompanyRentRoll },
  systemPowerCompanyType,
  isPG,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
} = loanFormModel.formField;

export class PropertyCompanyRentRollValidation implements ValidationSchema {
  public name = propertyCompanyRentRoll.name;
  public label = propertyCompanyRentRoll.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    isPG.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Property Company' &&
      propertyCompanyUploadRentRollOrProvideTenantInfo === 'Upload rent roll' &&
      isPersonalGuarantor !== 'Yes';
    schema = FileNameValidationSchema(propertyCompanyRentRoll.name, required);
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new PropertyCompanyRentRollValidation());
