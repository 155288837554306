import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { financialStatementLastYearQ3, applicantFiledTaxReturnsInThePast } =
  taxDocuments;

const { systemPowerUsage } = loanFormModel.formField;

export class FinancialStatementLastYearQ3Validation
  implements ValidationSchema
{
  public name = financialStatementLastYearQ3.name;
  public label = financialStatementLastYearQ3.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    systemPowerUsage.name,
    applicantFiledTaxReturnsInThePast.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(systemPowerUsage, applicantFiledTaxReturnsInThePast, schema) {
    const required =
      financialStatementLastYearQ3.displayCondition(
        applicantFiledTaxReturnsInThePast
      ) && financialStatementLastYearQ3.getRequired(systemPowerUsage);
    return FileNameValidationSchema(
      financialStatementLastYearQ3.name,
      required
    );
  }
}
export default new Validation(new FinancialStatementLastYearQ3Validation());
