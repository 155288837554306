import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { loanMonthlyPayment, loanBalance },
} = loanFormModel;

export class LoanMonthlyPaymentValidation implements ValidationSchema {
  public name = loanMonthlyPayment.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'number';
  public required = {
    message: loanMonthlyPayment.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
  public min = {
    message: 'Please enter a number greater than 0',
    value: 0.01,
  };

  public max = {
    message: 'Please enter an amount less than the total loan balance',
    value: Yup.ref(loanBalance.name) as any,
  };

  public typeError = {
    message: 'Please enter a number.',
    value: true,
  };
}
export default new Validation(new LoanMonthlyPaymentValidation());
