import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  defaultApplicantName,
  genericRequiredFieldError,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  systemPowerCompanyType,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  isPG,
} = loanFormModel.formField;

export class PropertyCompanyUploadRentRollOrProvideTenantInfoValidation
  implements ValidationSchema
{
  public name = propertyCompanyUploadRentRollOrProvideTenantInfo.name;
  public label =
    propertyCompanyUploadRentRollOrProvideTenantInfo.getLabel(
      defaultApplicantName
    );
  public dependsOn: string[] = [systemPowerCompanyType.name, isPG.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Property Company' &&
      isPersonalGuarantor !== 'Yes';

    if (!required) return schema.notRequired();
    return schema.required(genericRequiredFieldError);
  }
}
export default new Validation(
  new PropertyCompanyUploadRentRollOrProvideTenantInfoValidation()
);
