import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';
import { getEmailValidationSchema } from '../../utils/validation';
import PrincipalTypeValidation from './PrincipalTypeValidation';
import PrincipalTrustNameValidation from './PrincipalTrustNameValidation';
import PrincipalTrustTINValidation from './PrincipalTrustTINValidation';
import PrincipalTrustYearEstablishedValidation from './PrincipalTrustYearEstablishedValidation';
import PrincipalOccupationValidation from './PrincipalOccupationValidation';
import PrincipalOtherOccupationValidation from './PrincipalOtherOccupationValidation';
import PrincipalAddressValidation from './PrincipalAddressValidation';
import PrincipalBirthdateValidation from './PrincipalBirthdateValidation';
import PrincipalIsUSCitizenValidation from './PrincipalIsUSCitizenValidation';
import PrincipalNonUSPersonIdNumberValidation from './PrincipalNonUSPersonIdNumberValidation';
import PrincipalNonUSPersonIssuanceCountryValidation from './PrincipalNonUSPersonIssuanceCountryValidation';
import PrincipalSsnValidation from './PrincipalSsnValidation';
import PrincipalPhoneNumberValidation from './PrincipalPhoneNumberValidation';
import PrincipalCityValidation from './PrincipalCityValidation';
import PrincipalStateValidation from './PrincipalStateValidation';
import PrincipalCountryValidation from './PrincipalCountryValidation';
import PrincipalMortgagePaymentValidation from './PrincipalMortgagePaymentValidation';
import PrincipalRentValidation from './PrincipalRentValidation';
import PrincipalLiquidAssetsValidation from './PrincipalLiquidAssetsValidation';
import PrincipalPGAgreementValidation from './PrincipalPGAgreementValidation';
import PrincipalAnnualGrossIncomeValidation from './PrincipalAnnualGrossIncomeValidation';
import ApplicantSameAsPrincipalValidation from './ApplicantSameAsPrincipalValidation';
import PrincipalIsPGValidation from './PrincipalIsPGValidation';
import PrincipalPercentOwnedValidation from './PrincipalPercentOwnedValidation';
import PrincipalFirstNameValidation from './PrincipalFirstNameValidation';
import PrincipalLastNameValidation from './PrincipalLastNameValidation';
import PrincipalZipValidation from './PrincipalZipValidation';
import PrincipalIdDocumentFileNameValidation from './PrincipalIdDocumentFileNameValidation';
import PrincipalRentOrOwnValidation from './PrincipalRentOrOwnValidation';

const {
  formField: {
    // Principal
    principalFirstName,
    principalLastName,
    principalMiddleInitial,

    principalState,
    principalZip,
    principalCountry,
    principalType,

    principalEmailAddress,
    principalPercentOwned,

    // Personal Guarantor
    applicantSameAsPrincipal,
    principalIsPG,
    principalAnnualGrossIncome,
    principalLiquidAssets,
    principalRentOrOwn,
    principalMortgagePayment,
    principalRent,
    principalIdDocument,
    principalPGAgreement,
    __asapCount,
    __companyTypeValue,
  },
} = loanFormModel;

export class PrincipalsValidation implements ValidationSchema {
  public name = 'principals';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(schema: Yup.ArraySchema<Yup.ObjectSchema<any>>) {
    return schema
      .of(
        Yup.object().shape({
          [PrincipalTypeValidation.name]: PrincipalTypeValidation.buildSchema(),
          [PrincipalTrustNameValidation.name]:
            PrincipalTrustNameValidation.buildSchema(),
          [PrincipalTrustTINValidation.name]:
            PrincipalTrustTINValidation.buildSchema(),
          [PrincipalTrustYearEstablishedValidation.name]:
            PrincipalTrustYearEstablishedValidation.buildSchema(),
          [PrincipalOccupationValidation.name]:
            PrincipalOccupationValidation.buildSchema(),
          [PrincipalOtherOccupationValidation.name]:
            PrincipalOtherOccupationValidation.buildSchema(),
          [PrincipalAddressValidation.name]:
            PrincipalAddressValidation.buildSchema(),
          [PrincipalBirthdateValidation.name]:
            PrincipalBirthdateValidation.buildSchema(),
          [PrincipalIsUSCitizenValidation.name]:
            PrincipalIsUSCitizenValidation.buildSchema(),
          // US person validation
          [PrincipalNonUSPersonIdNumberValidation.name]:
            PrincipalNonUSPersonIdNumberValidation.buildSchema(),
          [PrincipalNonUSPersonIssuanceCountryValidation.name]:
            PrincipalNonUSPersonIssuanceCountryValidation.buildSchema(),
          [PrincipalSsnValidation.name]: PrincipalSsnValidation.buildSchema(),
          [PrincipalPhoneNumberValidation.name]:
            PrincipalPhoneNumberValidation.buildSchema(),
          [principalEmailAddress.name]: getEmailValidationSchema(
            principalEmailAddress
          ),
          [PrincipalPercentOwnedValidation.name]:
            PrincipalPercentOwnedValidation.buildSchema(),
          [PrincipalFirstNameValidation.name]:
            PrincipalFirstNameValidation.buildSchema(),
          [PrincipalLastNameValidation.name]:
            PrincipalLastNameValidation.buildSchema(),
          [principalMiddleInitial.name]: Yup.string().nullable(),
          // Address fields
          [PrincipalCityValidation.name]: PrincipalCityValidation.buildSchema(),
          [PrincipalStateValidation.name]:
            PrincipalStateValidation.buildSchema(),
          [PrincipalZipValidation.name]: PrincipalZipValidation.buildSchema(),
          [PrincipalCountryValidation.name]:
            PrincipalCountryValidation.buildSchema(),
          // PG-related fields
          [PrincipalIsPGValidation.name]: PrincipalIsPGValidation.buildSchema(),
          [ApplicantSameAsPrincipalValidation.name]:
            ApplicantSameAsPrincipalValidation.buildSchema(),
          [PrincipalAnnualGrossIncomeValidation.name]:
            PrincipalAnnualGrossIncomeValidation.buildSchema(),
          [PrincipalLiquidAssetsValidation.name]:
            PrincipalLiquidAssetsValidation.buildSchema(),
          [PrincipalRentValidation.name]: PrincipalRentValidation.buildSchema(),
          [PrincipalRentOrOwnValidation.name]:
            PrincipalRentOrOwnValidation.buildSchema(),
          [PrincipalMortgagePaymentValidation.name]:
            PrincipalMortgagePaymentValidation.buildSchema(),
          documents: Yup.object().shape({
            [principalIdDocument.name]: Yup.object().shape({
              files: Yup.array().of(
                Yup.object().shape({
                  file_name:
                    PrincipalIdDocumentFileNameValidation.buildSchema(),
                })
              ),
            }),
          }),
          // the .oneOf requires that the box must be checked (true)
          [PrincipalPGAgreementValidation.name]:
            PrincipalPGAgreementValidation.buildSchema(),
        })
      )
      .required();
  }
}
export default new Validation(new PrincipalsValidation());
