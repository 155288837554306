import * as Yup from 'yup';
import loanFormModel from './loanFormModel';
import {
  companyDocumentsValidation,
  hoaDocumentsValidation,
  solarSystemDocumentsValidation,
  additionalDocumentationValidation,
} from './requiredDocumentsValidation';
import {
  BankStatementsLastMonthValidation,
  BankStatements2MonthValidation,
  BankStatements3MonthValidation,
} from '../../../validations/bank_statements';
import {
  MaterialLoanObligationValidation,
  BorrowerAttestsNoDebtValidation,
} from '../../../validations/material_loan_obligation';
import {
  ApplicantFiledLastYearTaxReturnsValidation,
  TaxReturnsExtensionValidation,
  TaxReturns2YearValidation,
  ApplicantFiledTaxReturnsInThePastValidation,
  TaxReturns3YearValidation,
  IrsFormSS4Validation,
  TaxReturnsLastYearValidation,
  FinancialStatementQ1Validation,
  FinancialStatementQ2Validation,
  FinancialStatementQ3Validation,
  FinancialStatementLastYearQ3Validation,
  FinancialStatementFullYearValidation,
  PrincipalsStage2Validation,
} from '../../../validations/required_documents';

const {
  formField: {
    // System Power
    // Outstanding loans
    borrowerEsignName,
    borrowerSignerRole,
  },
} = loanFormModel;

const taxDocumentsValidation = {
  [ApplicantFiledTaxReturnsInThePastValidation.name]:
    ApplicantFiledTaxReturnsInThePastValidation.buildSchema(),
  [ApplicantFiledLastYearTaxReturnsValidation.name]:
    ApplicantFiledLastYearTaxReturnsValidation.buildSchema(),
  [IrsFormSS4Validation.name]: IrsFormSS4Validation.buildSchema(),
  [TaxReturnsExtensionValidation.name]:
    TaxReturnsExtensionValidation.buildSchema(),
  [TaxReturns2YearValidation.name]: TaxReturns2YearValidation.buildSchema(),
  [TaxReturns3YearValidation.name]: TaxReturns3YearValidation.buildSchema(),
  [TaxReturnsLastYearValidation.name]:
    TaxReturnsLastYearValidation.buildSchema(),
  [FinancialStatementQ1Validation.name]:
    FinancialStatementQ1Validation.buildSchema(),
  [FinancialStatementQ2Validation.name]:
    FinancialStatementQ2Validation.buildSchema(),
  [FinancialStatementQ3Validation.name]:
    FinancialStatementQ3Validation.buildSchema(),
  [FinancialStatementLastYearQ3Validation.name]:
    FinancialStatementLastYearQ3Validation.buildSchema(),
  [FinancialStatementFullYearValidation.name]:
    FinancialStatementFullYearValidation.buildSchema(),
};

export const principalsDocumentsValidation = {
  [PrincipalsStage2Validation.name]: PrincipalsStage2Validation.buildSchema(),
};

export const documentsValidation = {
  ...taxDocumentsValidation,
  ...companyDocumentsValidation,
  ...hoaDocumentsValidation,
  ...solarSystemDocumentsValidation,
  ...additionalDocumentationValidation,
  ...principalsDocumentsValidation,
};

export const bankStatements = {
  // TODO: Can't figure out how to get this to acknowledge when the value is filled in.  Persistent error state.
  // [`principals[0].${principalAverageCashBalance.name}`]: Yup.string()
  //   // .label(`principals[0].${principalAverageCashBalance.label}`)
  //   .nullable()
  //   .when(
  //     [isPG.name],
  //     (isPG, schema) => {
  //       if (isPG === 'Yes') return schema.required(principalAverageCashBalance.requiredErrorMsg);
  //       return schema;
  //     }
  //   ),
  [BankStatementsLastMonthValidation.name]:
    BankStatementsLastMonthValidation.buildSchema(),
  [BankStatements2MonthValidation.name]:
    BankStatements2MonthValidation.buildSchema(),
  [BankStatements3MonthValidation.name]:
    BankStatements3MonthValidation.buildSchema(),
};

export const outstandingLoans = {
  [BorrowerAttestsNoDebtValidation.name]:
    BorrowerAttestsNoDebtValidation.buildSchema(),
  [MaterialLoanObligationValidation.name]:
    MaterialLoanObligationValidation.buildSchema(),
};

export const consentValidation = {
  [borrowerEsignName.name]: Yup.string()
    .label(borrowerEsignName.label)
    .required(`${borrowerEsignName.requiredErrorMsg}`)
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.'-]*)$/gi,
      'Name can only contain English letters.'
    )
    // A full name. First and Last word must be atleast two characters, all words in between the first and last must be 1 character. Each word is counted as separated by a space.
    .matches(
      /^\s*[\S]{2,}(\s[\S]{1,})*(\s[\S]{2,})\s*$/gms,
      'Please enter your full name.'
    )
    .nullable(),
  [borrowerSignerRole.name]: Yup.string()
    .label(borrowerSignerRole.label)
    .required(`${borrowerSignerRole.requiredErrorMsg}`)
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.'-]*)$/gi,
      'Position/Title can only contain English letters.'
    )
    .min(3)
    .nullable(),
};

export const documentsValidationSchema =
  Yup.object().shape(documentsValidation);

export const bankStatementsValidationSchema =
  Yup.object().shape(bankStatements);

export const outstandingLoansSchema = Yup.object().shape(outstandingLoans);

export const stage2ReviewSchema = Yup.object().shape({
  ...outstandingLoans,
  ...documentsValidation,
});

export const consentValidationSchema = Yup.object().shape(consentValidation);

const validationSchema = [
  // Documents
  documentsValidationSchema,

  // Outstanding Loans
  outstandingLoansSchema,
  // Review
  stage2ReviewSchema,
  // Consent
  consentValidationSchema,
];

export const stage2ValidationFields = [
  documentsValidation,
  outstandingLoans,
  { ...outstandingLoans, ...documentsValidation },
  consentValidation,
];

export default validationSchema;
