import {
  Product,
  LoanCategory,
  RelatedParty,
  PlaidAssetReportStatus,
} from '../../../../types/api';
import loanFormModel from '../components/loan_application/form_models/loanFormModel';
import {
  MULTIPLE_USER_POWER_AGREEMENT,
  SYSTEM_POWER_USAGE_VALUES,
  COMPANY_TYPES,
} from '../constants';
import { ApplicationFormValues } from '../pages/dashboard/loan_detail/LoanDetailTypes';

const {
  borrowerBusinessEstablishedYear,
  systemPropertyCurrentlyPayingMortgage,
  nonProfitRecievedBoardApproval,
  borrowerIsReligiousOrganization,
  principalAverageCashBalance,
  systemPowerSingleOrMultipleUsers,
  systemPowerCommunityOrPPALease,
  systemPowerUsage,
  systemPropertyOwnershipStatus,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  systemPowerCompanyType,
  borrowerIsHighRisk,
  mailingAddressSameAsBusinessPicklist,
  borrowerYearsManagementInPlace,
  systemPowerTaxEquityPartnering,
  systemPropertyAddressIsSameAsBusiness,
  systemPropertyLeaseExpirationYear,
  loanCategory,
  borrowerHasCreditUnderOtherName,
  documents: { plaidAssetReport },
} = loanFormModel.formField;
export class BusinessRuleBase {
  protected data: Partial<ApplicationFormValues>;

  constructor(data: Partial<ApplicationFormValues>) {
    this.data = data;
  }

  public isPersonalGuarantor(): boolean {
    return this.data['is_personal_guarantor'] === 'Yes';
  }

  public isBorrowerReligiousOrg(): boolean {
    return this.data[borrowerIsReligiousOrganization.name] === 'Yes';
  }

  public isNonProfit(): boolean {
    return this.data[systemPowerCompanyType.name] === 'Non-Profit';
  }

  public hasBoardApproval(): boolean {
    return this.data[nonProfitRecievedBoardApproval.name] === 'Yes';
  }

  public isBorrowerStillPayingMortgage(): boolean {
    return this.data[systemPropertyCurrentlyPayingMortgage.name] === 'Yes';
  }

  public getBorrowerBusinessEstablishedYear(): number | undefined | null {
    return this.data[borrowerBusinessEstablishedYear.name];
  }

  public isBusinessEstablishedLessThan(years: number): boolean {
    const yearBusinessEstablished =
      this.data[borrowerBusinessEstablishedYear.name];
    return Boolean(
      yearBusinessEstablished &&
        new Date().getFullYear() - yearBusinessEstablished < years
    );
  }

  public isBorrowerManagementInPlaceLessThan(years: number): boolean {
    const managementYears = this.data[borrowerYearsManagementInPlace.name];
    return (managementYears && managementYears < years) || false;
  }

  public isPropertyCompany(): boolean {
    return this.data[systemPowerCompanyType.name] === 'Property Company';
  }

  public isHOA(): boolean {
    return this.data[systemPowerCompanyType.name] === 'HOA';
  }

  public isCompanyAgriculture(): boolean {
    return this.data[systemPowerCompanyType.name] === COMPANY_TYPES.AGRICULTURE;
  }

  public propertyCompanyHasSingleTenantInfo(): boolean {
    return (
      this.data[propertyCompanyUploadRentRollOrProvideTenantInfo.name] ===
      'Enter information for a single tenant'
    );
  }

  public propertyCompanyHasRentRoll(): boolean {
    return (
      this.data[propertyCompanyUploadRentRollOrProvideTenantInfo.name] ===
      'Upload rent roll'
    );
  }

  public isLeased(): boolean {
    return this.data[systemPropertyOwnershipStatus.name] === 'LEASED';
  }

  public isSingleUser(): boolean {
    return this.data[systemPowerSingleOrMultipleUsers.name] === 'Single User';
  }

  public isMultipleUser(): boolean {
    return (
      this.data[systemPowerSingleOrMultipleUsers.name] === 'Multiple Users'
    );
  }

  public isPowerPPALease(): boolean {
    return (
      this.data[systemPowerCommunityOrPPALease.name] ===
      MULTIPLE_USER_POWER_AGREEMENT.PPA_LEASE
    );
  }

  public isPowerCommunitySolar(): boolean {
    return (
      this.data[systemPowerCommunityOrPPALease.name] ===
      'Community Solar Arrangement'
    );
  }

  public isPPAOrLeaseAgreement(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      this.isMultipleUser() &&
      this.data[systemPowerCommunityOrPPALease.name] ===
        MULTIPLE_USER_POWER_AGREEMENT.PPA_LEASE
    );
  }

  public isCommunitySolar(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      this.isMultipleUser() &&
      this.data[systemPowerCommunityOrPPALease.name] ===
        'Community Solar Arrangement'
    );
  }

  public isSoldToSingleUser(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY && this.isSingleUser()
    );
  }

  public findApplicantSameAsPrincipal(): RelatedParty | undefined {
    return this.data.principals?.find(
      (principal) => principal['applicant_same_as_principal'] === 'Yes'
    );
  }

  public isSoldToAnotherEntity(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
      SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY
    );
  }

  public isOffset(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
      SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL
    );
  }

  public isFundedByTaxEquity(): boolean {
    return this.data[systemPowerTaxEquityPartnering.name] === 'Yes';
  }

  public getBorrowerBusinessName(): string {
    return this.data['borrower_business_name'] ?? 'Applicant';
  }

  public isBorrowerHighRisk(): boolean {
    return this.data[borrowerIsHighRisk.name] === 'Yes';
  }

  public isMailingAddressIsNotSameAsBusiness(): boolean {
    return this.data[mailingAddressSameAsBusinessPicklist.name] === 'No';
  }

  public getBusinessAddress(): string {
    const busAddress = this.data['business_address'];
    const busAddressCity = this.data['business_address_city'];
    const busAddressState = this.data['business_address_state'];
    const busAddressZipCode = this.data['business_address_zip_code'];
    if (
      !busAddress ||
      !busAddressCity ||
      !busAddressState ||
      !busAddressZipCode
    )
      return '';

    return `${busAddress}, ${busAddressCity}, ${busAddressState} ${busAddressZipCode}`;
  }

  public getSystemPropertyAddress(): string {
    const sysAddress = this.data['sys_prop_address'];
    const sysAddressCity = this.data['sys_prop_address_city'];
    const sysAddressState = this.data['sys_prop_address_state'];
    const sysAddressZipCode = this.data['sys_prop_address_zip_code'];
    if (
      !sysAddress ||
      !sysAddressCity ||
      !sysAddressState ||
      !sysAddressZipCode
    )
      return '';
    return `${sysAddress}, ${sysAddressCity}, ${sysAddressState} ${sysAddressZipCode}`;
  }

  public isSystemPropertyAddressSameAsBusiness(): boolean {
    return this.data[systemPropertyAddressIsSameAsBusiness.name] === 'Yes';
  }

  public getSystemPropertyExpirationYear(): number {
    return this.data[systemPropertyLeaseExpirationYear.name] ?? 0;
  }

  // Get the warning message related to system_property_lease_expiration_year
  // showed when lease term is less than 80% of loan term
  public getLease80percentLoanTermWarning(): string {
    return systemPropertyLeaseExpirationYear.lease80percentLoanTermWarning;
  }

  public getLoanProduct(): Product | undefined {
    return this.data['product'];
  }

  public isExpressPlus(): boolean {
    return this.data[loanCategory.name] === LoanCategory.EXPRESS_PLUS;
  }

  public hasBorrowerCreditUnderAnotherName(): boolean {
    return this.data[borrowerHasCreditUnderOtherName.name] === 'Yes';
  }

  public getAverageCashBalance(): number {
    return this.data['principals']?.[0][principalAverageCashBalance.name];
  }

  public isAssetReportSuccessful(): boolean {
    return (
      this.data[plaidAssetReport.name]?.asset_report_status ===
      PlaidAssetReportStatus.SUCCESS
    );
  }
}
