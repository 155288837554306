import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    pgFiledTaxes1YearAgo,
    applicantSameAsPrincipal,
    pgFiledTaxes2YearsAgo,
  },
} = loanFormModel;

const { pgTaxes2YearsAgo } = taxDocuments;

export class PgTaxes2YearsAgoValidation implements ValidationSchema {
  public name = pgTaxes2YearsAgo.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'object';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public buildShape(schema: Yup.ObjectSchema<any>) {
    return schema.shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            file_name: Yup.string(),
          })
        )
        .test(
          'check-required-if-undefined',
          'This document is required',
          function (files) {
            // (this as any).from[2] traverses back up the object tree to the principal so that we can read principal_type
            if (
              (this as any).from[2].value[applicantSameAsPrincipal.name] ===
              'Yes'
            ) {
              if (
                ((this as any).from[2].value[pgFiledTaxes1YearAgo.name] ===
                  'No' &&
                  (this as any).from[2].value[pgFiledTaxes2YearsAgo.name] ===
                    'Yes') ||
                ((this as any).from[2].value[pgFiledTaxes1YearAgo.name] ===
                  'No' &&
                  !(this as any).from[2].value[pgFiledTaxes2YearsAgo.name])
              ) {
                return !!(
                  files &&
                  files[0]?.file_name != null &&
                  files[0]?.file_name !== ''
                );
              }
              return true;
            }
            return true;
          }
        ),
    });
  }
}
export default new Validation(new PgTaxes2YearsAgoValidation());
