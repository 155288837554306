import { AccountBusinessStructure } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { GenericValidation } from '../GenericValidation';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    systemPowerUsage,
    systemPowerCompanyType,
    nonProfitRecievedBoardApproval,
  },
} = loanFormModel;

export class NonProfitRecievedBoardApprovalValidation
  implements ValidationSchema
{
  public name: string = nonProfitRecievedBoardApproval.name;
  public label: string = nonProfitRecievedBoardApproval.getLabel('Applicant');
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerCompanyType.name,
  ];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: nonProfitRecievedBoardApproval.required,
  };
  public nullable = true;

  when(
    usage: SYSTEM_POWER_USAGE_TYPE,
    companyType: AccountBusinessStructure,
    schema
  ) {
    if (
      usage === SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL &&
      companyType === AccountBusinessStructure.NON_PROFIT
    ) {
      return schema;
    }
    return GenericValidation.create({
      name: this.name,
      type: 'string',
      label: this.label,
      required: {
        value: false,
      },
      nullable: true,
    });
  }
}
export default new Validation(new NonProfitRecievedBoardApprovalValidation());
