import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  financialStatementFullYear,
  applicantFiledTaxReturnsInThePast,
  applicantFiledLastYearTaxReturns,
} = taxDocuments;

const { systemPowerUsage, isPG } = loanFormModel.formField;

export class FinancialStatementFullYearValidation implements ValidationSchema {
  public name = financialStatementFullYear.name;
  public label = financialStatementFullYear.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    systemPowerUsage.name,
    applicantFiledTaxReturnsInThePast.name,
    applicantFiledLastYearTaxReturns.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    systemPowerUsage,
    applicantFiledTaxReturnsInThePast,
    applicantFiledLastYearTaxReturns,
    schema
  ) {
    const required =
      financialStatementFullYear.displayCondition(
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns
      ) && financialStatementFullYear.getRequired(systemPowerUsage);
    return FileNameValidationSchema(financialStatementFullYear.name, required);
  }
}
export default new Validation(new FinancialStatementFullYearValidation());
