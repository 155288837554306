import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { mailingAddressZipCode, mailingAddressSameAsBusinessPicklist },
} = loanFormModel;

export class MailingAddressZipCodeValidation implements ValidationSchema {
  public name: string = mailingAddressZipCode.name;
  public label: string = mailingAddressZipCode.label;
  public dependsOn: string[] = [mailingAddressSameAsBusinessPicklist.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    mailingAddressSameAsBusinessPicklist: YesNo,
    schema: Yup.StringSchema
  ) {
    return mailingAddressSameAsBusinessPicklist === 'Yes'
      ? schema
      : schema
          .label(mailingAddressZipCode.label)
          .min(5, 'Must be exactly 5 digits')
          .max(5, 'Must be exactly 5 digits')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required()
          .nullable();
  }
}
export default new Validation(new MailingAddressZipCodeValidation());
