import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    pgFiledTaxes1YearAgo,
    pgFiledTaxes2YearsAgo,
    pgFiledTaxes3YearsAgo,
  },
} = loanFormModel;

const { pgExtension3YearsAgo } = taxDocuments;

export class PgExtension3YearsAgoValidation implements ValidationSchema {
  public name = pgExtension3YearsAgo.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'object';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public buildShape(schema: Yup.ObjectSchema<any>) {
    return schema.shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            file_name: Yup.string(),
          })
        )
        .test(
          'check-required-if-undefined',
          'This document is required',
          function (files) {
            // (this as any).from[2] traverses back up the object tree to the principal so that we can read principal_type
            if (
              (this as any).from[2].value[pgFiledTaxes3YearsAgo.name] ===
                'No' &&
              (this as any).from[2].value[pgFiledTaxes2YearsAgo.name] ===
                'No' &&
              (this as any).from[2].value[pgFiledTaxes1YearAgo.name] === 'No'
            ) {
              return !!(
                files &&
                files[0]?.file_name != null &&
                files[0]?.file_name !== ''
              );
            }
            return true;
          }
        ),
    });
  }
}
export default new Validation(new PgExtension3YearsAgoValidation());
