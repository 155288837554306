import { YesNo } from '../../../../../types/api';
import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { applicantFiledTaxReturnsInThePast, taxReturns2Year } = taxDocuments;

const { systemPowerUsage, borrowerBusinessEstablishedYear } =
  loanFormModel.formField;

export class TaxReturns2YearValidation implements ValidationSchema {
  public name = taxReturns2Year.name;
  public label = taxReturns2Year.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    borrowerBusinessEstablishedYear.name,
    systemPowerUsage.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    filedTaxReturns: YesNo,
    businessEstablishedYear,
    systemPowerUsage,
    schema
  ) {
    const required =
      taxReturns2Year.displayCondition(
        filedTaxReturns,
        businessEstablishedYear
      ) && taxDocuments.taxReturns2Year.getRequired(systemPowerUsage);
    return FileNameValidationSchema(taxReturns2Year.name, required);
  }
}
export default new Validation(new TaxReturns2YearValidation());
