import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    borrowerIsHighRisk,
    borrowerBusinessStructure,
    borrowerIsHighRiskExplanation,
  },
} = loanFormModel;

export class BorrowerIsHighRiskExplanationValidation
  implements ValidationSchema
{
  public name: string = borrowerIsHighRiskExplanation.name;
  public label = '';
  public dependsOn: string[] = [
    borrowerBusinessStructure.name,
    borrowerIsHighRisk.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    borrowerBusinessStructure: string,
    borrowerIsHighRisk: string,
    schema: Yup.StringSchema
  ) {
    if (borrowerBusinessStructure === 'NON-PROFIT') return schema.notRequired();
    if (borrowerIsHighRisk === 'Yes')
      return schema.required(genericRequiredFieldError);
    return schema.notRequired();
  }
}
export default new Validation(new BorrowerIsHighRiskExplanationValidation());
