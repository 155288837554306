import * as Yup from 'yup';
import loanFormModel from './loanFormModel';
import { genericRequiredFieldError, LIMITS } from '../../../constants';

import {
  SystemPowerUsageValidation,
  CompanyTypeValidation,
  NonProfitRecievedBoardApprovalValidation,
  DescribeBorrowerValidation,
  SingleOrMultipleUsersValidation,
  PowerTaxEquityPartneringValidation,
  TaxEquityFundingPercentageValidation,
  OfftakerNeedsPercentageValidation,
  OfftakerUtilityRateDiscountValidation,
  CommunityOrPPALeaseValidation,
  NumberOfOfftakersValidation,
  LegalNamesOfOfftakersValidation,
  SubscribersAmountValidation,
  SubscribersDiscountValidation,
  UtilityJurisdictionNameValidation,
  SubscriberManagementCompanyValidation,
} from '../../../validations/solar_system_power/';
import {
  PropertyAddressCityValidation,
  PropertyAddressIsSameAsBusinessValidation,
  PropertyAddressValidation,
  PropertyAddressStateValidation,
  PropertyAddressZipCodeValidation,
  PropertyOwnershipStatusValidation,
  PropertyCurrentlyPayingMortgageValidation,
  PropertyAppraisedValueValidation,
  PropertyLandlordConsentValidation,
  PropertyLegalNameOfOwnerValidation,
  PropertyOwnerAffiliatedWithBorrowerValidation,
  PropertyLeaseExpirationYearValidation,
  OutstandingLoanValidation,
} from '../../../validations/solar_system_property';
import {
  BorrowerIsHighRiskExplanationValidation,
  BorrowerIsHighRiskValidation,
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressValidation,
  BusinessAddressZipCodeValidation,
  MailingAddressCityValidation,
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressStateValidation,
  MailingAddressValidation,
  MailingAddressZipCodeValidation,
  PrimaryContactNameValidation,
  PrimaryContactPhoneNumberValidation,
  PrimaryContactEmailValidation,
} from '../../../validations/borrower_information';
import { defaultBorrowerLegalName } from './constants';
import PrincipalsValidation from '../../../validations/principals/PrincipalsValidation';
const {
  formField: {
    // Business Identification
    borrowerTIN,

    // Ownership History
    borrowerBusinessStructure,
    borrowerIsReligiousOrganization,
    borrowerReligiousParentOrgSupport,
    borrowerReligiousLeaderYears,
    borrowerStateOfOrganization,
    borrowerLinesOfBusiness,
    borrowerBusinessEstablishedDate,
    borrowerBusinessEstablishedYear,
    borrowerGrossRevenueLatest,
    borrowerYearsManagementInPlace,
    borrowerManagementExperienceYears,

    // Bank, Taxes, Lawsuits
    borrowerHasCreditUnderOtherName,
    finInfoNameCreditWasObtainedUnder,
  },
} = loanFormModel;

export const borrowerInformationValidation = {
  [PrimaryContactNameValidation.name]:
    PrimaryContactNameValidation.buildSchema(),
  [PrimaryContactEmailValidation.name]:
    PrimaryContactEmailValidation.buildSchema(),
  [PrimaryContactPhoneNumberValidation.name]:
    PrimaryContactPhoneNumberValidation.buildSchema(),
  [MailingAddressSameAsBusinessPicklistValidation.name]:
    MailingAddressSameAsBusinessPicklistValidation.buildSchema(),
  [MailingAddressValidation.name]: MailingAddressValidation.buildSchema(),
  [MailingAddressCityValidation.name]:
    MailingAddressCityValidation.buildSchema(),
  [MailingAddressStateValidation.name]:
    MailingAddressStateValidation.buildSchema(),
  [MailingAddressZipCodeValidation.name]:
    MailingAddressZipCodeValidation.buildSchema(),
  [BorrowerIsHighRiskValidation.name]:
    BorrowerIsHighRiskValidation.buildSchema(),
  [BorrowerIsHighRiskExplanationValidation.name]:
    BorrowerIsHighRiskExplanationValidation.buildSchema(),
};

export const businessAddressValidation = {
  [BusinessAddressValidation.name]: BusinessAddressValidation.buildSchema(),
  [BusinessAddressCityValidation.name]:
    BusinessAddressCityValidation.buildSchema(),
  [BusinessAddressStateValidation.name]:
    BusinessAddressStateValidation.buildSchema(),
  [BusinessAddressZipCodeValidation.name]:
    BusinessAddressZipCodeValidation.buildSchema(),
};

export const businessIdentification = {
  ...borrowerInformationValidation,
  ...businessAddressValidation,
};

export const solarSystemProperty = {
  [PropertyAddressIsSameAsBusinessValidation.name]:
    PropertyAddressIsSameAsBusinessValidation.buildSchema(),
  // Address fields
  [PropertyAddressValidation.name]: PropertyAddressValidation.buildSchema(),
  [PropertyAddressCityValidation.name]:
    PropertyAddressCityValidation.buildSchema(),
  [PropertyAddressStateValidation.name]:
    PropertyAddressStateValidation.buildSchema(),
  [PropertyAddressZipCodeValidation.name]:
    PropertyAddressZipCodeValidation.buildSchema(),
  [PropertyOwnershipStatusValidation.name]:
    PropertyOwnershipStatusValidation.buildSchema(),
  // owned ownership validation
  [PropertyCurrentlyPayingMortgageValidation.name]:
    PropertyCurrentlyPayingMortgageValidation.buildSchema(),
  [PropertyAppraisedValueValidation.name]:
    PropertyAppraisedValueValidation.buildSchema(),
  // Outstanding Loan Obligation for Mortgage required if systemPropertyCurrentlyPayingMortgage === 'Yes' and ownershipType === 'OWN'
  [OutstandingLoanValidation.name]: OutstandingLoanValidation.buildSchema(),
  // leased ownership validation
  [PropertyLandlordConsentValidation.name]:
    PropertyLandlordConsentValidation.buildSchema(),
  [PropertyLegalNameOfOwnerValidation.name]:
    PropertyLegalNameOfOwnerValidation.buildSchema(),
  [PropertyOwnerAffiliatedWithBorrowerValidation.name]:
    PropertyOwnerAffiliatedWithBorrowerValidation.buildSchema(),
  [PropertyLeaseExpirationYearValidation.name]:
    PropertyLeaseExpirationYearValidation.buildSchema(),
};

export const systemPower = {
  [SystemPowerUsageValidation.name]: SystemPowerUsageValidation.buildSchema(),
  [CompanyTypeValidation.name]: CompanyTypeValidation.buildSchema(),
  [NonProfitRecievedBoardApprovalValidation.name]:
    NonProfitRecievedBoardApprovalValidation.buildSchema(),
  [DescribeBorrowerValidation.name]: DescribeBorrowerValidation.buildSchema(),
  [SingleOrMultipleUsersValidation.name]:
    SingleOrMultipleUsersValidation.buildSchema(),
  [PowerTaxEquityPartneringValidation.name]:
    PowerTaxEquityPartneringValidation.buildSchema(),
  [TaxEquityFundingPercentageValidation.name]:
    TaxEquityFundingPercentageValidation.buildSchema(),
  [OfftakerNeedsPercentageValidation.name]:
    OfftakerNeedsPercentageValidation.buildSchema(),
  [OfftakerUtilityRateDiscountValidation.name]:
    OfftakerUtilityRateDiscountValidation.buildSchema(),
  [CommunityOrPPALeaseValidation.name]:
    CommunityOrPPALeaseValidation.buildSchema(),
  [NumberOfOfftakersValidation.name]: NumberOfOfftakersValidation.buildSchema(),
  [LegalNamesOfOfftakersValidation.name]:
    LegalNamesOfOfftakersValidation.buildSchema(),
  [SubscribersAmountValidation.name]: SubscribersAmountValidation.buildSchema(),
  [SubscribersDiscountValidation.name]:
    SubscribersDiscountValidation.buildSchema(),
  [UtilityJurisdictionNameValidation.name]:
    UtilityJurisdictionNameValidation.buildSchema(),
  [SubscriberManagementCompanyValidation.name]:
    SubscriberManagementCompanyValidation.buildSchema(),
};

export const ownershipHistory = {
  [borrowerGrossRevenueLatest.name]: Yup.number()
    .nullable()
    .required('This field is required'),
  [borrowerTIN.name]: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits')
    .required(`${borrowerTIN.requiredErrorMsg}`)
    .nullable(),
  [borrowerBusinessStructure.name]: Yup.string()
    .required(`${borrowerBusinessStructure.requiredErrorMsg}`)
    .nullable(),
  [borrowerIsReligiousOrganization.name]: Yup.string()
    .nullable()
    .when(
      [borrowerBusinessStructure.name],
      (borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure === 'NON-PROFIT') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerReligiousParentOrgSupport.name]: Yup.string()
    .nullable()
    .when(
      [borrowerIsReligiousOrganization.name],
      (borrowerIsReligiousOrganization, schema) => {
        if (borrowerIsReligiousOrganization === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerReligiousLeaderYears.name]: Yup.string()
    .nullable()
    .when(
      [borrowerIsReligiousOrganization.name],
      (borrowerIsReligiousOrganization, schema) => {
        if (borrowerIsReligiousOrganization === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerStateOfOrganization.name]: Yup.string()
    .nullable()
    .when(
      [borrowerBusinessStructure.name],
      (borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure !== 'SOLE PROPRIETORSHIP') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerLinesOfBusiness.name]: Yup.string()
    .required(`${borrowerLinesOfBusiness.requiredErrorMsg}`)
    .nullable(),
  [borrowerBusinessEstablishedYear.name]: Yup.number()
    .nullable()
    .min(LIMITS.MIN_YEAR_ALLOWED, 'Please enter a valid year.')
    .max(new Date().getFullYear(), 'Please enter a valid year.')
    .required('This field is required'),
  [borrowerYearsManagementInPlace.name]: Yup.number()
    .nullable()
    .label(borrowerYearsManagementInPlace.getLabel(defaultBorrowerLegalName))
    .required(genericRequiredFieldError)
    .when(
      [borrowerBusinessEstablishedYear.name],
      (borrowerBusinessEstablishedYear, schema) => {
        // This value can't be greater than the difference between the current year and the year the business was established
        const currentYear = new Date().getFullYear();
        // if borrowerBusinessEstablishedYear is a valid year and is at least >= 1900, then we can calculate the max value
        const establishedYear = Number(borrowerBusinessEstablishedYear);
        if (establishedYear && establishedYear >= LIMITS.MIN_YEAR_ALLOWED) {
          const maxYearsManagementInPlace = currentYear - establishedYear;
          return schema.max(maxYearsManagementInPlace);
        }
        return schema;
      }
    ),
  [borrowerManagementExperienceYears.name]: Yup.number()
    .nullable()
    .when(
      [borrowerYearsManagementInPlace.name],
      (borrowerYearsManagementInPlace, schema) => {
        if (borrowerYearsManagementInPlace < 15)
          return schema.required(genericRequiredFieldError);
        return schema.notRequired();
      }
    )
    .max(LIMITS.MAX_YEARS_ESTABLISHED),
};

// everything, all the time
export const principals = {
  [borrowerHasCreditUnderOtherName.name]: Yup.string()
    .required(`${borrowerHasCreditUnderOtherName.requiredErrorMsg}`)
    .nullable(),
  [finInfoNameCreditWasObtainedUnder.name]: Yup.string()
    .nullable()
    .when(
      [borrowerHasCreditUnderOtherName.name],
      (borrowerHasCreditUnderOtherName, schema) => {
        if (borrowerHasCreditUnderOtherName === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [PrincipalsValidation.name]: PrincipalsValidation.buildSchema(),
};

export const businessIdentificationSchema = Yup.object().shape(
  businessIdentification
);

export const solarSystemPropertySchema =
  Yup.object().shape(solarSystemProperty);

export const systemPowerSchema = Yup.object().shape(systemPower);

export const ownershipHistorySchema = Yup.object().shape(ownershipHistory, [
  borrowerBusinessEstablishedDate.name,
]);

export const principalsSchema = Yup.object().shape(principals);
