import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { leaseOfTheSystemProperty } =
  loanFormModel.formField.solarSystemDocuments;
const { systemPropertyOwnershipStatus } = loanFormModel.formField;

export class LeaseOfTheSystemPropertyValidation implements ValidationSchema {
  public name = leaseOfTheSystemProperty.name;
  public label = '';
  public dependsOn: string[] = [systemPropertyOwnershipStatus.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist'],
    schema
  ) {
    if (systemPropertyOwnershipStatus === 'LEASED')
      return FileNameValidationSchema(
        leaseOfTheSystemProperty.name,
        true
      ).required('This document is required');
    return FileNameValidationSchema(
      leaseOfTheSystemProperty.name,
      false
    ).notRequired();
  }
}
export default new Validation(new LeaseOfTheSystemPropertyValidation());
