import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';
import LoanBalanceValidation from './LoanBalanceValidation';
import LoanYearOfMaturityValidation from './LoanYearOfMaturityValidation';
import LoanMonthlyPaymentValidation from './LoanMonthlyPaymentValidation';
import LoanPurposeValidation from './LoanPurposeValidation';
import LoanLenderValidation from './LoanLenderValidation';
import OutstandingLoanTypeValidation from './OutstandingLoanTypeValidation';

const {
  formField: { borrowerAttestsNoDebt },
} = loanFormModel;

export class MaterialLoanObligationValidation implements ValidationSchema {
  public name = 'outstanding_loans';
  public label = '';
  public dependsOn: string[] = [borrowerAttestsNoDebt.name];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(
    borrowerAttestsNoDebt: boolean,
    schema: Yup.ArraySchema<Yup.ObjectSchema<any>>
  ) {
    if (borrowerAttestsNoDebt) return schema.max(0);
    return schema
      .of(
        Yup.object().shape({
          [LoanLenderValidation.name]: LoanLenderValidation.buildSchema(),
          [OutstandingLoanTypeValidation.name]:
            OutstandingLoanTypeValidation.buildSchema(),
          [LoanPurposeValidation.name]: LoanPurposeValidation.buildSchema(),
          [LoanBalanceValidation.name]: LoanBalanceValidation.buildSchema(),
          [LoanYearOfMaturityValidation.name]:
            LoanYearOfMaturityValidation.buildSchema(),
          [LoanMonthlyPaymentValidation.name]:
            LoanMonthlyPaymentValidation.buildSchema(),
        })
      )
      .min(1, 'Please add at least one loan.');
  }
}
export default new Validation(new MaterialLoanObligationValidation());
