import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { businessAddressZipCode },
} = loanFormModel;

export class BusinessAddressZipCodeValidation implements ValidationSchema {
  public name: string = businessAddressZipCode.name;
  public label: string = businessAddressZipCode.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: businessAddressZipCode.required,
  };
  public nullable = true;
  public min = { value: 5, message: 'Must be exactly 5 digits' };
  public max = { value: 5, message: 'Must be exactly 5 digits' };
  public matches = {
    value: /^[0-9]+$/,
    message: 'Must be only digits',
  };
}
export default new Validation(new BusinessAddressZipCodeValidation());
