import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import {
  genericRequiredFieldError,
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    systemPowerUsage,
    systemPowerSingleOrMultipleUsers,
    systemPowerCommunityOrPPALease,
    systemPowerSubscribersAmount,
  },
} = loanFormModel;

export class SubscribersAmountValidation implements ValidationSchema {
  public name: string = systemPowerSubscribersAmount.name;
  public label: string = systemPowerSubscribersAmount.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
    systemPowerCommunityOrPPALease.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  when(
    usage: SYSTEM_POWER_USAGE_TYPE,
    singleOrMultipleUsers: string,
    communityOrPPALease: string,
    schema
  ) {
    if (
      usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      singleOrMultipleUsers === 'Multiple Users' &&
      communityOrPPALease === 'Community Solar Arrangement'
    ) {
      return schema.required(genericRequiredFieldError);
    }
    return schema;
  }
}
export default new Validation(new SubscribersAmountValidation());
