import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import {
  genericRequiredFieldError,
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { systemPowerUsage, systemPowerDescribeBorrower },
} = loanFormModel;

export class DescribeBorrowerValidation implements ValidationSchema {
  public name: string = systemPowerDescribeBorrower.name;
  public label: string = systemPowerDescribeBorrower.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [systemPowerUsage.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(systemPowerUsage: SYSTEM_POWER_USAGE_TYPE, schema) {
    if (systemPowerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY) {
      return schema
        .required(genericRequiredFieldError)
        .test(
          'isNonEmptyIfOther',
          "Please Enter 'Other' description if you selected 'Other'",
          (value) => {
            // Value should not equal empty 'Other: ' string,
            return value !== 'Other: ';
          }
        );
    }
    return schema.nullable();
  }
}
export default new Validation(new DescribeBorrowerValidation());
