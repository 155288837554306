import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { borrowerIsHighRisk, borrowerBusinessStructure },
} = loanFormModel;

export class BorrowerIsHighRiskValidation implements ValidationSchema {
  public name: string = borrowerIsHighRisk.name;
  public label = '';
  public dependsOn: string[] = [borrowerBusinessStructure.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(borrowerBusinessStructure: string, schema: Yup.StringSchema) {
    if (borrowerBusinessStructure !== 'NON-PROFIT') {
      return schema.required(borrowerIsHighRisk.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new BorrowerIsHighRiskValidation());
