import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: {
    systemPropertyOwnershipStatus,
    systemPropertyCurrentlyPayingMortgage,
  },
} = loanFormModel;

export class PropertyCurrentlyPayingMortgageValidation
  implements ValidationSchema
{
  public name: string = systemPropertyCurrentlyPayingMortgage.name;
  public label = '';
  public dependsOn: string[] = [systemPropertyOwnershipStatus.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(ownershipType: string, schema: Yup.StringSchema) {
    if (ownershipType === 'OWNED')
      return schema.required('This field is required');
    return schema;
  }
}
export default new Validation(new PropertyCurrentlyPayingMortgageValidation());
