import { subYears } from 'date-fns';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { RELATED_PARTY_TYPES } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalType, principalBirthdate },
} = loanFormModel;

export class PrincipalBirthdateValidation implements ValidationSchema {
  public name = principalBirthdate.name;
  public label = principalBirthdate.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'date';
  public required = {
    message: principalBirthdate.requiredErrorMsg,
    value: principalBirthdate.required,
  };
  public nullable = true;
  public typeError = { value: true, message: 'Please enter a valid date.' };
  public max = {
    value: subYears(new Date(), 18) as any,
    message: 'Principal must be at least 18 years old',
  };

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
      return schema.required(principalBirthdate.requiredErrorMsg);
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalBirthdateValidation());
