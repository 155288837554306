import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { approvalForCommunitySolarDelivery } =
  loanFormModel.formField.solarSystemDocuments;

export class ApprovalForCommunitySolarDeliveryValidation
  implements ValidationSchema
{
  public name = approvalForCommunitySolarDelivery.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(schema) {
    return FileNameValidationSchema(
      approvalForCommunitySolarDelivery.name,
      false
    );
  }
}
export default new Validation(
  new ApprovalForCommunitySolarDeliveryValidation()
);
