import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  companyDocuments: { nonProfitBylawsCopy },
  systemPowerCompanyType,
  isPG,
} = loanFormModel.formField;

export class NonProfitBylawsCopyValidation implements ValidationSchema {
  public name = nonProfitBylawsCopy.name;
  public label = nonProfitBylawsCopy.getLabel(defaultApplicantName);
  public dependsOn: string[] = [systemPowerCompanyType.name, isPG.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Non-Profit' && isPersonalGuarantor !== 'Yes';
    schema = FileNameValidationSchema(nonProfitBylawsCopy.name, required);
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new NonProfitBylawsCopyValidation());
