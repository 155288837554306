import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const { currentOccupantLedger } = loanFormModel.formField.hoaDocuments;
const { systemPowerCompanyType } = loanFormModel.formField;

export class CurrentOccupantLedgerValidation implements ValidationSchema {
  public name = currentOccupantLedger.name;
  public label = '';
  public dependsOn: string[] = [systemPowerCompanyType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    schema
  ) {
    if (systemPowerCompanyType === 'HOA')
      return FileNameValidationSchema(
        currentOccupantLedger.name,
        true
      ).required('This document is required');
    return FileNameValidationSchema(
      currentOccupantLedger.name,
      false
    ).notRequired();
  }
}
export default new Validation(new CurrentOccupantLedgerValidation());
