import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  genericRequiredFieldError,
  SYSTEM_POWER_USAGE_TYPE,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    systemPowerUsage,
    systemPowerSingleOrMultipleUsers,
    systemPowerCommunityOrPPALease,
  },
} = loanFormModel;

export class CommunityOrPPALeaseValidation implements ValidationSchema {
  public name: string = systemPowerCommunityOrPPALease.name;
  public label: string = systemPowerCommunityOrPPALease.label;
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  when(usage: SYSTEM_POWER_USAGE_TYPE, singleOrMultipleUsers: string, schema) {
    if (
      usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      singleOrMultipleUsers === 'Multiple Users'
    ) {
      return schema.required(genericRequiredFieldError);
    }
    return schema;
  }
}
export default new Validation(new CommunityOrPPALeaseValidation());
