import loanFormModel, {
  taxDocuments,
} from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  applicantFiledTaxReturnsInThePast,
  taxReturnsLastYear,
  applicantFiledLastYearTaxReturns,
} = taxDocuments;

const { systemPowerUsage } = loanFormModel.formField;

export class TaxReturnsLastYearValidation implements ValidationSchema {
  public name = taxReturnsLastYear.name;
  public label = taxReturnsLastYear.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    applicantFiledLastYearTaxReturns.name,
    systemPowerUsage.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    applicantFiledTaxReturnsInThePast,
    applicantFiledLastYearTaxReturns,
    systemPowerUsage,
    schema
  ) {
    const required =
      taxReturnsLastYear.displayCondition(
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns
      ) && taxReturnsLastYear.getRequired(systemPowerUsage);
    return FileNameValidationSchema(taxReturnsLastYear.name, required);
  }
}
export default new Validation(new TaxReturnsLastYearValidation());
