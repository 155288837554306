import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import {
  Application,
  InstallerDashboardSummary,
  InstallerProjectSummary,
  SunstoneDocuments,
  PaginatedResult,
  RequiredDocuments,
  NonUniqueRequiredDocumentsList,
  RequiredDocument,
} from '../../../../types/api';
import {
  getPaginatedInstallerApplications,
  closeApplication,
  getApplicationDocuments,
  getInstallerDashboardSummary,
  getInstallerProjectSummary,
  InstallerApplicationsParams,
  uploadApplicationDocument,
} from '../services/apis/InstallerAPIs';

export const INSTALLER_APPS_QUERY = 'installerApplications';

type InstallerApplicationsKey = [
  typeof INSTALLER_APPS_QUERY,
  InstallerApplicationsParams
];
const getInstallerApplicationsKey = (
  params: InstallerApplicationsParams
): InstallerApplicationsKey => [INSTALLER_APPS_QUERY, params];
export const useInstallerApplications = (
  params: InstallerApplicationsParams,
  options: UseQueryOptions<
    PaginatedResult<Application>,
    unknown,
    PaginatedResult<Application>,
    InstallerApplicationsKey
  > = {}
) =>
  useQuery(
    getInstallerApplicationsKey(params),
    (context) => getPaginatedInstallerApplications(context.queryKey[1]),
    options
  );

type InstallerDashboardSummaryKey = [typeof INSTALLER_APPS_QUERY, 'summary'];
export const useInstallerDashboardSummary = (
  options?: UseQueryOptions<
    InstallerDashboardSummary,
    unknown,
    InstallerDashboardSummary,
    InstallerDashboardSummaryKey
  >
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, 'summary'] as InstallerDashboardSummaryKey,
    getInstallerDashboardSummary,
    {
      ...options,
    }
  );
};

type InstallerProjectSummaryKey = [
  typeof INSTALLER_APPS_QUERY,
  'summary/projects'
];
export const useInstallerProjectSummary = (
  options?: UseQueryOptions<
    InstallerProjectSummary,
    unknown,
    InstallerProjectSummary,
    InstallerProjectSummaryKey
  >
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, 'summary/projects'] as InstallerProjectSummaryKey,
    getInstallerProjectSummary,
    {
      ...options,
    }
  );
};

type InstallerApplicationMutations = 'remove';
type InstallerApplicationMutationKey = [
  typeof INSTALLER_APPS_QUERY,
  InstallerApplicationMutations
];
const getInstallerApplicationMutationKey = (
  mutation: InstallerApplicationMutations
): InstallerApplicationMutationKey => [INSTALLER_APPS_QUERY, mutation];

export const useCloseInstallerApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(
    getInstallerApplicationMutationKey('remove'),
    closeApplication,
    {
      onSuccess: async () => {
        await queryClient.cancelQueries(INSTALLER_APPS_QUERY);
        queryClient.invalidateQueries(INSTALLER_APPS_QUERY);
        queryClient.invalidateQueries(['projects']);
      },
    }
  );
};

export const useInstallerApplicationDocuments = (
  applicationId,
  options = {}
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, applicationId, 'documents'],
    () => {
      return getApplicationDocuments(applicationId);
    },
    options
  );
};

type InstallerApplicationDocumentMutations = 'upload';
type InstallerApplicationDocumentMutationKey = [
  typeof INSTALLER_APPS_QUERY,
  'documents',
  InstallerApplicationDocumentMutations
];
const getInstallerApplicationDocumentMutationKey = (
  mutationType: InstallerApplicationDocumentMutations
): InstallerApplicationDocumentMutationKey => [
  INSTALLER_APPS_QUERY,
  'documents',
  mutationType,
];
type UpdateInstallerDocumentsVariables = {
  applicationId: string;
  data: FormData;
  documentType: keyof SunstoneDocuments;
  filename: string;
};
export const useUpdateInstallerDocuments = (
  options: UseMutationOptions<
    RequiredDocument,
    unknown,
    UpdateInstallerDocumentsVariables,
    unknown
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<
    RequiredDocument,
    unknown,
    UpdateInstallerDocumentsVariables,
    unknown
  >(
    getInstallerApplicationDocumentMutationKey('upload'),
    ({ applicationId, data }) => {
      return uploadApplicationDocument(applicationId, data);
    },
    {
      onSuccess: (
        uploadedDocument,
        { applicationId, documentType, filename }
      ) => {
        queryClient.setQueryData<RequiredDocuments>(
          [INSTALLER_APPS_QUERY, applicationId, 'documents'],
          (oldData) => {
            const baseData = oldData || {};
            let updates: Partial<RequiredDocuments> = {};
            const docType = uploadedDocument.document_type.name;

            if (NonUniqueRequiredDocumentsList.includes(docType as any)) {
              const existingDocs =
                (baseData as Record<string, RequiredDocument[]>)[docType] || [];
              const restBaseData = { ...baseData };
              // remove the existing documents from the base data so we can append the new document
              delete restBaseData[docType];

              updates = {
                [docType]: [...existingDocs, uploadedDocument],
              };

              return {
                ...restBaseData,
                ...updates,
              } as RequiredDocuments;
            } else {
              updates = {
                [documentType]: uploadedDocument,
              };

              return {
                ...baseData,
                ...updates,
              } as RequiredDocuments;
            }
          }
        );
      },
      ...options,
    }
  );
};
