import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';

const {
  companyDocuments: { nonProfitBoardResolution },
  systemPowerCompanyType,
  isPG,
  nonProfitRecievedBoardApproval,
} = loanFormModel.formField;

export class NonProfitBoardResolutionValidation implements ValidationSchema {
  public name = nonProfitBoardResolution.name;
  public label = nonProfitBoardResolution.label;
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    nonProfitRecievedBoardApproval.name,
    isPG.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    nonProfitRecievedBoardApproval: Application['non_profit_received_board_approval'],
    isPersonalGuarantor: Application['is_personal_guarantor'],
    schema
  ) {
    const required =
      systemPowerCompanyType === 'Non-Profit' &&
      nonProfitRecievedBoardApproval === 'Yes' &&
      isPersonalGuarantor !== 'Yes';
    if (required)
      return FileNameValidationSchema(
        nonProfitBoardResolution.name,
        true
      ).required('This document is required');
    return FileNameValidationSchema(nonProfitBoardResolution.name, false);
  }
}
export default new Validation(new NonProfitBoardResolutionValidation());
