import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalZip },
} = loanFormModel;

export class PrincipalZipValidation implements ValidationSchema {
  public name = principalZip.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: principalZip.requiredErrorMsg,
    value: principalZip.required,
  };
  public nullable = true;
  public min = {
    value: 5,
    message: 'Must be exactly 5 digits',
  };
  public max = {
    value: 5,
    message: 'Must be exactly 5 digits',
  };
  public matches = {
    value: /^[0-9]+$/,
    message: 'Must be only digits',
  };
}
export default new Validation(new PrincipalZipValidation());
