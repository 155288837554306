import { PlaidAssetReport, YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { SYSTEM_POWER_USAGE_TYPE } from '../../constants';
import { GenericValidation } from '../GenericValidation';
import * as Yup from 'yup';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import { BankStatementsBL } from '../../business/BankStatementsBL';

const {
  formField: {
    isPG,
    systemPowerUsage,
    principalAverageCashBalance,
    documents: { bankStatementLastMonth, plaidAssetReport },
  },
} = loanFormModel;

export class BankStatementsLastMonthValidation implements ValidationSchema {
  public name: string = bankStatementLastMonth.name;
  public label: string = bankStatementLastMonth.label;
  public dependsOn: string[] = [
    plaidAssetReport.name,
    systemPowerUsage.name,
    isPG.name,
    `principals[0].${principalAverageCashBalance.name}`,
  ];
  public type = 'string';
  public required = {
    message: 'Required document missing',
    value: bankStatementLastMonth.required,
  };
  public nullable = true;

  public when = (
    assetReport: PlaidAssetReport,
    systemPowerUsageValue: SYSTEM_POWER_USAGE_TYPE,
    isPGValue: YesNo,
    averageCashBalance: number,
    schema
  ): any => {
    const bl = new BankStatementsBL({
      [plaidAssetReport.name]: assetReport,
      [systemPowerUsage.name]: systemPowerUsageValue,
      [isPG.name]: isPGValue,
      principals: [
        { [principalAverageCashBalance.name]: averageCashBalance } as any,
      ],
    });
    // When asset report is present,
    //  or systemPowerUsage is "Sold to another entity"
    //  or isPersonalGuarantor is "Yes",
    // don't require bank statements

    if (!bl.isBankStatementRequired()) {
      return Yup.object().shape({
        files: Yup.array().of(
          Yup.object().shape({
            file_name: GenericValidation.create({
              name: this.name,
              type: 'string',
              label: this.label,
              nullable: true,
            }),
          })
        ),
      });
    }
    return schema;
  };

  public buildShape(schema) {
    return Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object()
            .shape({
              file_name: schema,
            })
            .required()
        )
        .min(1)
        .required(),
    });
  }
}
export default new Validation(new BankStatementsLastMonthValidation());
