import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';
import PrincipalCertificateOfTrustValidation from './PrincipalCertificateOfTrustValidation';

const {
  formField: { principalCertificateOfTrust, principalType },
} = loanFormModel;

export class PrincipalsValidation implements ValidationSchema {
  public name = 'principals';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(schema: Yup.ArraySchema<Yup.ObjectSchema<any>>) {
    return schema
      .of(
        Yup.object().shape({
          [PrincipalCertificateOfTrustValidation.name]:
            PrincipalCertificateOfTrustValidation.buildSchema(),
        })
      )
      .required();
  }
}
export default new Validation(new PrincipalsValidation());
