import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { systemPropertyAddressIsSameAsBusiness, systemPropertyAddress },
} = loanFormModel;

export class PropertyAddressValidation implements ValidationSchema {
  public name: string = systemPropertyAddress.name;
  public label: string = systemPropertyAddress.label;
  public dependsOn: string[] = [systemPropertyAddressIsSameAsBusiness.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(isSameAsBusiness: YesNo, schema: Yup.StringSchema) {
    if (isSameAsBusiness === 'No') return schema.required();
    return schema;
  }
}
export default new Validation(new PropertyAddressValidation());
